import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TechCarousel = ({ items }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '70px',
  };

  return (
    <Slider {...settings}>
      {items.map((tech, index) => (
        <div key={index}>
          <img src={tech.image} alt="" />
        </div>
      ))}
    </Slider>
  );
};
export default TechCarousel;
