import React from 'react'
import './Footer.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from '../../Assets/Images/logo.webp'
import { Link } from 'react-router-dom';

export const Footer = () => {

    const handleClick = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };


    return (



        <div className='footer'>
            <div className='div1 '>

                <Container>
                    <Row className="justify-content-md-center Lblock">
                        <Col xs lg="2" className='footerlogo'>
                            <Link to="/">    <img src={Logo} alt="" /></Link>        </Col>
                        {/* <Col md="auto">Variable width content</Col> */}

                    </Row>
                    <Row className='footer-pages-nav Lblock'>
                        <Col xs={2} md={2} lg={1}>   <Link to="/games" onClick={handleClick}>Games</Link>  </Col>
                        <Col xs={2} md={2} lg={1}>   <Link to="/apps" onClick={handleClick}>Apps</Link>  </Col>
                        <Col xs={2} md={2} lg={1}>   <Link to="/services" onClick={handleClick}>Services</Link>  </Col>
                        <Col xs={2} md={2} lg={1}>   <Link to="/career" onClick={handleClick}>Career</Link>  </Col>
                        <Col xs={2} md={2} lg={2}>   <Link to="/lifeatmobify" onClick={handleClick}>Life at Mobify</Link>  </Col>
                        <Col xs={2} md={2} lg={2} className='Lblock'>  <Link to="/contact" onClick={handleClick}>Contact Us</Link></Col>
                        <Col xs={2} md={2}  className='Mblock'>  <Link to="/contact" onClick={handleClick}> Contact</Link></Col>

                    </Row>

                    <Row className='justify-content-center Lblock' style={{paddingBottom:'0.7rem'}}>

                        <Col xs={6} md={12}>Islamabad, Pakistan | Casper,WY USA</Col>


                    </Row>

                    <Row className='justify-content-center'  >

                    <Col className='Mblock' style={{marginTop:'7px'}} xs={12} md={3} ><p>Islamabad, Pakistan | Casper,WY USA</p></Col>


                    </Row>


                    <Row className='justify-content-center M14 newfooterblock' style={{paddingBottom:'0.7rem'}}>
                
                        <Col xs={6} md={4} xxl={2} className='email Lblock' style={{ borderRight: '2px solid #63676B',height:'31px' }}>Hr@mobify.tech</Col>
                        <Col className='Lblock' xs={6} md={4} xxl={2} ><Link to="/privacypolicy" onClick={handleClick} style={{textDecoration:'none',color:'#63676B'}}> Privacy Policy </Link></Col>

                        <Col className='email Mblock' xs={4} md={4} xxl={2} style={{ borderRight: '1.9px solid #63676B', height:'16px' }}> <p>Hr@mobify.tech</p></Col>
                        <Col xs={4} md={4} xxl={2}  className='Mblock terms'><a href='/privacypolicy' style={{textDecoration:'none',color:'#63676B'}} ><p  style={{ textDecoration:'none' }}>Terms & Privacy</p></a>
</Col>
                        
                    </Row>
                
                    <Row className='justify-content-center'>
                        <Col><p>©2024 Mobify. All Rights Reserved.</p></Col>
                    </Row>

                    <Row style={{ gap: '10px' }} className='justify-content-center icon-col FMView'>

                        <Col xs={2} md="auto">
                            <a href="https://www.linkedin.com/company/mobifytech/" target='_blank' rel="noreferrer">
                                <i className="fa fa-linkedin" aria-hidden="true"></i>
                            </a>
                        </Col>

                        <Col xs={2} md="auto">
                            <a href="http://www.youtube.com/@MobifyPK" target='_blank' rel="noreferrer">
                                <i className="fa fa-youtube-play" aria-hidden="true"></i>
                            </a>
                        </Col>

                        <Col xs={2} md={"auto"}>
                            <a href="https://www.facebook.com/profile.php?id=61556523691762" target='_blank' rel="noreferrer">
                                <i className="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                        </Col>
                        <Col xs={2} md="auto">
                            <a href="https://www.instagram.com/mobify_pk/" target='_blank' rel="noreferrer">
                                <i className="fa fa-instagram" aria-hidden="true"></i>
                            </a>
                        </Col>




                    </Row>


                </Container>





            </div>


        </div>
    )
}
