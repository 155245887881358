import React from 'react'

import './About.css'
import Lottie from 'react-lottie';
import { Carousel } from 'react-bootstrap';

import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'; // Import arrow icons
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { Card, Col, Container, Row } from 'react-bootstrap';






import { useEffect, useState } from 'react';

import sphere1 from '../../Assets/Website Assets/About/Sphere1.webp'
import sphere2 from '../../Assets/Website Assets/About/Sphere2.webp'
import sphere3 from '../../Assets/Website Assets/About/Sphere3.webp'
import sphere4 from '../../Assets/Website Assets/About/Sphere4.webp'
import sphere5 from '../../Assets/Website Assets/About/Sphere5.webp'


import NewAbout from '../../Assets/Website Assets/About/newAbout.webp'

import NewVision from '../../Assets/Website Assets/About/NewVision.webp'



import M from '../../Assets/Website Assets/About/Mastery.webp'
import O from '../../Assets/Website Assets/About/Optimism.webp'
import B from '../../Assets/Website Assets/About/Balance.webp'
import I from '../../Assets/Website Assets/About/Innovation.webp'
import FY from '../../Assets/Website Assets/About/Flexibility.webp'



import HomeImpactBanner from '../../Assets/Website Assets/Home/HomeImpactBanner.webp'

import ClockAnim from '../../Assets/Website Assets/Animations/New/Home page G.json'

import ImpactHM2 from '../../Assets/Website Assets/Home/ImpactHM2.webp'
import ImpactHM3 from '../../Assets/Website Assets/Home/ImpactHM3.webp'

import blueprint from '../../Assets/Website Assets/About/Blueprint.webp'


import backimgdiv7 from '../../Assets/Website Assets/About/AboutImpactBanner.webp'


import HeroAnim from '../../Assets/Website Assets/Animations/New/About.json'


import Cross1 from '../../Assets/Website Assets/About/Cross1.webp'
import Cross2 from '../../Assets/Website Assets/About/Cross2.webp'
import Circle1 from '../../Assets/Website Assets/About/Circle1.webp'
import Circle2 from '../../Assets/Website Assets/About/Circle2.webp'

import RockDiag from '../../Assets/Website Assets/About/RockDiag.webp'

import AboutCircle1 from  '../../Assets/Website Assets/About/AboutCircle1.webp'
import AboutCircle2 from  '../../Assets/Website Assets/About/AboutCircle2.webp'

import Diag1 from '../../Assets/Website Assets/About/Diag1.webp'
import Diag2 from '../../Assets/Website Assets/About/Diag2.webp'




import border from '../../Assets/Website Assets/About/border.webp'





import Mabout1 from '../../Assets/Website Assets/About/Mabout1.webp'
import Mabout2 from '../../Assets/Website Assets/About/Mabout2.webp'
// import Mabout3 from '../../Assets/Website Assets/About/Mabout3.webp'
import Mabout4 from '../../Assets/Website Assets/About/Mabout4.webp'



import CustomAccordion from '../Common/CustomAccordion';

import Maccord from '../../Assets/Website Assets/About/M-accord.webp'
import Oaccord from '../../Assets/Website Assets/About/O-accord.webp'
import Baccord from '../../Assets/Website Assets/About/B-accord.webp'
import Iaccord from '../../Assets/Website Assets/About/I-accord.webp'
import FYaccord from '../../Assets/Website Assets/About/FY-accord.webp'



export const About = () => {
  

// In your React component
useEffect(() => {
  document.title = "About"; // Set your desired page title here
  document.querySelector('meta[name="title"]').setAttribute("content", "About | Mobify");
  document.querySelector('meta[name="description"]').setAttribute("content", "To make lives delightful with the best products.");
  document.querySelector('meta[name="keywords"]').setAttribute("content", "mobify, mobify islamabad, game development, app development");
}, []);


  const accordionItems = [
    {
      imageSrc: Maccord,
      title: 'Mastery',
      initialText: 'Pursuing excellence in every endeavor, constantly refining ',
      expandedText: 'skills,  and achieving mastery in the ever-evolving landscape of technology.',
    },
    {
      imageSrc: Oaccord,
      title: 'Optimism',
      initialText: 'Staying positive and hopeful, believing in challenges as',
      expandedText: 'opportunities for innovation and success.',
    },
    {
      imageSrc: Baccord,
      title: 'Balance',
      initialText: 'We believe in blending innovation with stability, speed with quality,',
      expandedText: 'and flexibility with standardization for optimal performance.',
    },
    {
      imageSrc: Iaccord,
      title: 'Innovation',
      initialText: 'Embracing a culture of fearless exploration, pushing boundaries,',
      expandedText: 'and bringing visionary ideas to life through cutting-edge innovation',
    },
    {
      imageSrc: FYaccord,
      title: 'Flexibility',
      initialText: 'We, as an agile organization, respond swiftly to ',
      expandedText: 'new opportunities, and proactively ensuring sustained excellence in the ever-changing IT landscape.',
    },
    // Add more items as needed
  ];








  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations in milliseconds
      easing: 'ease', // Easing function for animations
      once: true, // Whether animations should only happen once
    });
  }, []);




  const items = [
    { title: 'Vision', description: 'We offer full-cycle game development, covering everything from creating concept art and visuals to development services and post-production support. We offer full-cycle game development, covering everything from creating concept art and visuals to development services and post-production support.' },
    { title: 'Mission', description: 'Our mission at Mobify is to go beyond creating a dream workplace; we aim to establish a vibrant hub where tasks ignite energy, fostering passion in our team. We firmly believe that an energized workforce is the key to crafting the world\'s most exceptional products.' },
  ];


 // Flipping Card Animation

 const [div7InView, setDiv7InView] = useState(false);

 useEffect(() => {
   const div7Element = document.getElementById('div7');
 
   if (div7Element) {
     const options = {
       root: null, // Use the viewport as the root
       rootMargin: '0px', // No margin
       threshold: 0.8 // Trigger when at least 50% of the target element is visible
     };
 
     const observer = new IntersectionObserver((entries) => {
       const entry = entries[0];
       if (entry.isIntersecting) {
         // The target element is now in the viewport
         setDiv7InView(true);
         // Call your animation function here
         animateCard('products-number', 52, 3);
         animateCard('downloads-number', 2.3, 4);
         animateCard('team-number', 148, 3);
         // Disconnect the observer after animation is triggered
         observer.disconnect();
       }
     }, options);
 
     observer.observe(div7Element);
 
     return () => {
       observer.disconnect();
     };
   }
 }, []);

 // Function to animate the card flipping and incrementing
 function animateCard(cardId, finalNumber, duration) {
   let currentNumber = 0;
   const increment = finalNumber / duration;
   const interval = setInterval(() => {
     currentNumber += increment;
     updateNumber(cardId, Math.floor(currentNumber));
     if (currentNumber >= finalNumber) {
       updateNumber(cardId, finalNumber);
       clearInterval(interval);
     }
   }, 1000); // Adjust the interval as needed for animation speed
 }


 // Function to update the number on the card dynamically
 function updateNumber(cardId, number) {
   const element = document.getElementById(cardId);
   if (element) {
     element.textContent = number;
   }
 }


  // Function to update the number on the card dynamically
  function updateNumber(cardId, number) {
    const element = document.getElementById(cardId);
    if (element) {
      element.textContent = number;
    }
  }



  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: HeroAnim, // Pass the imported animation data
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


  const Anim2 = {
    loop: true,
    autoplay: true,
    animationData: ClockAnim, // Pass the imported animation data
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className='about'>
      <div className="div1">
        <div className="mycontainer">



          <img src={sphere1} className='sphere1 Lblock' alt="" />
          <img src={sphere2} className='sphere2 Lblock' alt="" />
          <img src={sphere3} className='sphere3 Lblock' alt="" />
          <img src={sphere4} className='sphere4 Lblock' alt="" />
          <img src={sphere5} className='sphere5 Lblock' alt="" />

          <img src={Mabout1} className='sphere1 Mblock' alt="" />
          <img src={Mabout2} className='sphere2 Mblock' alt="" />
          <img src={sphere3} className='sphere3 Mblock' alt="" />
          <img src={Mabout4} className='sphere4 Mblock' alt="" />



          {/* <img src={div1img1} className='img1' alt="" />
        <img src={div1img2} className='img2' alt="" />
        <img src={div1img3} className='img3' alt="" /> */}
        </div>

        <Container>
          <Row>

            <Col xs={12} md={6} lg={5} xl={6} className='col'>
              <h1>Mobify,Where <br /> digital smiles <br /> bloom</h1>
            </Col>
            
            <Col sm={12} md={6} className='col  LAnim'>
            <Lottie className='GamePadAnim' options={defaultOptions} style={{width:'39vw', height:'auto'}} />
            </Col>
            <Col sm={12} md={6} className='col MAnim'>
            <Lottie className='hero' options={defaultOptions} style={{width:'80vw', height:'auto'}} />
            {/* <Lottie className='hero' options={Anim1} style={{ height: '100%', width: '100%' }} /> */}

</Col>

 


          </Row>
        </Container>
      </div>



      <div className="div2">

        <img src={Cross1} className='Cross1 Lblock' alt="" />
        <img src={Circle1} className='Circle1 Lblock' alt="" />
        <img src={Circle2} className='Circle2 Lblock' alt="" />
        <img src={Cross2} className='Cross2 Lblock' alt="" />

        <Container data-aos="fade-up" data-aos-duration="1000">
          <Row >
            <Col xs={12} >
              <h1>At Mobi<span>fy</span></h1>
              <h2>we Infuse our realm with</h2>
            </Col>


            <Col xs={12} >

              <img className='NewAboutImg Lblock' src={NewAbout} alt="" />


            </Col>
            
          </Row>
          <Row className=' spec-row Lblock' >
            
        


            
              <Col className='ml-1' xs={3} md={2}>
                <h3>The magic of <span>mastery</span></h3>

              </Col>
              <Col xs={3} md={2}>
                <h3>The spark of <span>optimism</span></h3>

              </Col>
              <Col xs={3} md={2}>
                <h3>The dance of <span>balance</span></h3>

              </Col>
              <Col className='mtop-10 moff-5' xs={3} md={2}>
                <h3>The thrill of <span>innovation</span></h3>

              </Col>
              <Col className='mtop-10' xs={3} md={2}>
                <h3>The graceful <span>flexibility</span></h3>

              </Col>

          </Row>

<div className='Mblock realm' data-aos="fade-up"
     data-aos-duration="1500">


        <Row >
          <Col xs={4}>
          <img src={M} alt="" />
          </Col>

          <Col xs={5}>
          <h3>The magic of <span>MASTERY</span></h3>
          </Col>

        </Row>

        <Row >
          <Col xs={4}>
          <img src={O} alt="" />
          </Col>

          <Col xs={5}>
          <h3>The spark of <span>OPTIMISM</span></h3>
          </Col>

        </Row>

        <Row >
          <Col xs={4}>
          <img src={B} alt="" />
          </Col>

          <Col xs={5}>
          <h3>The dance of <span>Balance</span></h3>
          </Col>

        </Row>



        <Row >
          <Col xs={4}>
          <img src={I} alt="" />
          </Col>

          <Col xs={5}>
          <h3>The thrill of <span>Innovation</span></h3>
          </Col>

        </Row>


        <Row >
          <Col xs={4}>
          <img src={FY} alt="" />
          </Col>

          <Col xs={6}>
          <h3>The graceful <span>Flexibility</span></h3>
          </Col>

        </Row>


        </div>






        </Container>



      </div>


      <div className="div3 special-margin" >
        
        <img src={Cross1} alt="" data-aos="zoom-in"  className='Cross1 Lblock' />
        <img src={Cross1} alt="" data-aos="zoom-in" className='Cross2 Lblock' />
        <img src={RockDiag} alt="" data-aos="zoom-in" className='RockDiag Lblock' />

        <Container className=''  >

          <Row className='spec-row'>
            {/* <Col xs={12}>

                </Col> */}
            <img src={NewVision} className='VisionImg Lblock' data-aos="zoom-in" alt="" data-aos-duration="1500" />
            {/* <img src={NewVisionM} className='VisionImg Mblock' alt="" /> */}


            <Col md={5} className='about-col mb-17' data-aos="fade-up"    data-aos-duration="2000">
              <div className='text'>
              <h3>Vision</h3>
              {/* <h4>We offer full-cycle game development, covering everything from creating concept art and visuals to development services and post-production support. We offer full-cycle game development, covering everything from creating concept art and visuals to development services and post-production support. </h4> */}
                <h4 className='vision'>To make lives delightful with the best products</h4>
                </div>
            </Col>
            <Col md={5} className='about-col ' data-aos="fade-up"     data-aos-duration="2000">
            <div className='text text2'>

              <h3>Mission</h3>
              <h4 className=' mission jt'>To go beyond creating a dream workplace; we aim to establish a vibrant hub where tasks ignite energy, fostering passion in our team. We firmly believe that an energized workforce is the key to crafting the world's most exceptional products. </h4>
              </div>

            </Col>
          </Row>

        </Container>



      </div>


      <div className="div4 Lblock">
        
        <img src={AboutCircle1} alt=""  className='AboutCircle1' data-aos="fade-up"  />
        <img src={Cross1} alt=""  className='AboutCross' data-aos="fade-up"/>
        <img src={AboutCircle2} alt="" className='AboutCircle2' data-aos="fade-up"/>


        <Container>
          <Row>
            <Col xs={12} data-aos="fade-up">
              <h2>Why we started | What we do</h2>
            </Col>
            <Row className='row1' data-aos="fade-right">               
                <Col md={6}>
              <h1>Our Pursuits</h1>
            </Col>
              <Col className='col1' md={6}>
                <p className='p1'>At Mobify, our inception was driven by a purpose to create opportunities for the youth, fostering self-sustainability and skills in the emerging industry of games and apps. With a dual commitment to contribute to our country's economic growth and compete globally, we envision not just entertaining but providing purposeful solutions. Beyond products, we believe in exploring new horizons of tech and innovation, ensuring our business becomes a catalyst for positive change, progress, and empowering the youth for success in the dynamic landscape of digital experiences.</p>
              </Col>
            </Row>

            <Row className='row2' data-aos="fade-left">
              <Col className='col2' md={6}>
                <p className='p2'>At Mobify, we specialize in crafting world class games, apps, and web solutions, driven by an unwavering commitment to unparalleled quality. Our dedication extends beyond products to the development of skilled professionals equipped with both a sound mind and advanced skill sets, fostering excellence in the industry. As ardent believers in being the benchmark for quality, in our field globally, we consistently generate innovative ideas, cultivate masterful minds, and diligently work to develop, debug, and publish products that redefine industry standards.</p>
              </Col>
              <Col md={6}>
                <h1>Initial Drive</h1>
              </Col>
            </Row>


          </Row>
        </Container>

      </div>

      <div className="div4 carousel-container Mblock"  data-aos="fade-up"
     data-aos-duration="2000">
    
    <Container>

      <h2>Why we started <br /> What we do</h2>
    
      <Carousel
        nextIcon={<BsChevronRight className="carousel-control-icon" />}
        prevIcon={<BsChevronLeft className="carousel-control-icon" />}
        indicators={false}
      >
        <Carousel.Item>
          <div className='div4Mblock'>
            <h3>Our Pursuits</h3>
            <p>At Mobify, we specialize in crafting world-class games, apps, and web solutions, driven by an unwavering commitment to unparalleled quality. Our dedication extends beyond products to the development of skilled professionals equipped with both a sound mind and advanced skill sets, fostering excellence in the industry. As ardent believers in being the benchmark for quality, in our field globally, we consistently generate innovative ideas, cultivate masterful minds, and diligently work to develop, debug, and publish products that redefine industry standards.</p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className='div4Mblock'>
            <h3>Initial Drive</h3>
            <p>At Mobify, our inception was driven by a purpose — to create opportunities for the youth, fostering self-sustainability and skills in the emerging industry of games and apps. With a dual commitment to contribute to our country's economic growth and compete globally, we envision not just entertaining but providing purposeful solutions. Beyond products, we believe in exploring new horizons of tech and innovation, ensuring our business becomes a catalyst for positive change, progress, and empowering the youth for success in the dynamic landscape of digital experiences.</p>
          </div>
        </Carousel.Item>
      </Carousel>
      </Container>

    </div>


      <div className="div5 essence" style={{background: '#F9F9F9'}}> 
        {/* <img src={div2img1} className='img1' alt="" />
        <img src={div2img2} className='img2' alt="" />
        <img src={div2img3} className='img3' alt="" />
        <img src={div2img4} className='img4' alt="" /> */}


<Col xs={12} className='Mblock' data-aos="fade-up"
     data-aos-duration="1000"> {/* Use xs={12} to span the full width on extra small screens */}
    <h2>Mobi<span style={{color:'#F69E1F'}}>fy</span> Essence</h2>
  </Col>


<div className='Mblock MobAccord' data-aos="fade-up"
     data-aos-duration="1000">

<CustomAccordion items={accordionItems} />

  </div>






        <img src={Diag1} className='Diag1 Lblock' alt="" />
        <img src={Diag2} className='Diag2 Lblock' alt="" />

        <Container>
        <Row>
  <Col xs={12} className='Lblock' data-aos="fade-up"      data-aos-duration="1000"> {/* Use xs={12} to span the full width on extra small screens */}
    <h2>Mobi<span style={{color:'#F69E1F'}}>fy</span> Essence</h2>
  </Col>


  <Row className='blueprints row1' data-aos="fade-up"
     data-aos-duration="1000">




<Col xs={{span:9}} md={{ span: 4, offset: 0 }} lg={{ span: 3, offset: 0 }}  xl={{ span: 2, offset: 0 }}  className='mt5 topMargin1 Lblock'><h3><span>M</span>astery</h3>
  <h4>Pursuing excellence in every endeavor, constantly refining skills, and achieving mastery in the ever-evolving landscape of technology. </h4>
 </Col>
 <Col xs={{span:9, offset: 0}} md={{ span: 4, offset: 0 }} lg={{ span: 3, offset: 0 }} xl={{ span: 2, offset: 0 }}   className='mt5 Lblock'><h3><span>B</span>alance</h3>
  <h4>We believe in blending innovation with stability, speed with quality, and flexibility with standardization for optimal performance. </h4>
 </Col>


 <Col md={{ span: 4, offset: 0 }} lg={{ span: 3, offset: 0 }}  xl={{ span: 2, offset: 0 }}   className='mt5 Lblock'><h3><span>F</span>lexibilit<span>y</span></h3>
  <h4>We, as an agile organization, respond swiftly to new opportunities, and proactively ensuring sustained excellence in the ever-changing IT landscape. </h4>
 </Col>
      


    

 <Col md={{ span: 4, offset: 1 }} lg={{ span: 3, offset: 1 }}  xl={{ span: 2, offset: 1 }}   className='mt-5 Optimism m-top Lblock'><h3><span>O</span>ptimism</h3>
  <h4>Staying positive and hopeful, believing in challenges as opportunities for innovation and success. </h4>
 </Col>

 <Col md={{ span: 4, offset: 0 }} lg={{ span: 3, offset: 0 }} xl={{ span: 2, offset: 0 }}  className='mt-5 m-top Lblock'><h3><span>I</span>nnovation</h3>
  <h4>Embracing a culture of fearless exploration, pushing boundaries, and bringing visionary ideas to life through cutting-edge innovation
</h4>
 </Col>

 </Row>


  
  <Row className='blueprints'>

  <Col xs={12} className='blueprintCol Lblock'>
  <div className='blueprint Lblock' >

  <img src={blueprint} alt=""  />
  
  </div>


  </Col>
  








  </Row>


  
</Row>

        </Container>
  </div>


  <div id="div7" className="div7 impact" style={{ backgroundImage: `url(${backimgdiv7})` }}     >

<img src={ImpactHM2} className='Mblock ImpactHM2' alt="" data-aos="fade-up"
     data-aos-duration="1000"/>
<img src={ImpactHM3} className='Mblock ImpactHM3' alt="" data-aos="fade-up"
     data-aos-duration="1000"/>

<Container>
  <Row>
    <Col xs={12} data-aos="fade-up"
     data-aos-duration="1000">
      <h2>Impact & Reach</h2>
    </Col>
  </Row>
  <Row className='MReverse' >
    <Col className='Mview' md={6}>
      <Row className='spec-row'  data-aos="fade-up" data-aos-duration="1500">
        <Col  xs={4} className='spec-col card1'>
          <img src={border} className='bor1' alt="" />
          <Card id="products-card">
            <Card.Body>
              <Card.Text>
                <h3><span id="products-number">0</span>+</h3>
                <h4>Products</h4>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={4} className='spec-col'>
          <img src={border} className='bor1' alt="" />
          <Card id="downloads-card">
            <Card.Body>
              <Card.Text>
                <h3><span id="downloads-number">0</span>B</h3>
                <h4>Downloads</h4>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={4} className='spec-col card2'>
          <Card id="team-card">
            <Card.Body>
              <Card.Text>
                <h3><span id="team-number">0</span>+</h3>
                <h4>Team</h4>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>


      </Row>
    </Col>
    <Col className='ImpactCol' md={5}  data-aos="fade-up" data-aos-duration="1500">
      <img src={HomeImpactBanner} className='ImpactBanner' alt="" />
      {/* <img className='alarm' src={div7img} alt="" /> */}
      <div className='impactAnimMargin Lblock'>
        <Lottie className='clockAnim' options={Anim2} style={{ height: 'auto', width: '31vw' }}/>
      </div>
      <div className='impactAnimMargin Mblock'>
        <Lottie className='clockAnim' options={Anim2} style={{ height: 'auto', width: '88vw' }}/>
      </div>

    </Col>
  </Row>
</Container>


</div>

    </div>
  )
}
