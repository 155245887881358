import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Card } from 'react-bootstrap';
import play from '../../Assets/Website Assets/Games/Games/play.webp'
import ios from '../../Assets/Website Assets/Games/Games/ios.webp'
import { useEffect } from 'react';


const GameCarousel = ({ items }) => {

  const [activeIndex, setActiveIndex] = useState(0);

  const [overlayVisible, setOverlayVisible] = useState(false);


  const toggleOverlay = (index) => {
    setActiveIndex(index);
    setOverlayVisible(!overlayVisible);
  };

  // useEffect(() => {
  //   // Hide the overlay when the slider changes
  //   setOverlayVisible(false);
  // }, [activeIndex]);

  const handleBeforeChange = (oldIndex, newIndex) => {
    // Hide the overlay when the slide is changing
    setOverlayVisible(false);
    setActiveIndex(newIndex);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '60px',
    beforeChange: (current, next) => setActiveIndex(next)
  };

  return (
    <Slider {...settings} beforeChange={handleBeforeChange}>
      {items.map((item, index) => (
        <div key={index}>
<Card>
  <div  onClick={() => toggleOverlay(index)} >
    <Card.Img variant="top" id="opImg" src={item.image} />
    <div className={index === activeIndex && overlayVisible ? 'overlay overlayBack' : 'overlay'}></div>
    {overlayVisible && activeIndex === index && (
      <div className=' overlay-buttons' style={{height:'0'}}>
                  <a  href={item.url1}>
                    <img className="play" src={play} alt="Play Store" />
                  </a>
                  <a  href={item.url2}>
                    <img className="ios" src={ios} alt="iOS" />
                  </a>


      </div>

    )}
  </div>
  <Card.Body>
    {activeIndex === index ? (
      <Card.Text>
        <h3>{item.title}</h3>
        <h4>{item.subtitle}</h4>
      </Card.Text>
    ) : null}
  </Card.Body>
</Card>

        </div>
      ))}
    </Slider>
  );
};

export default GameCarousel;
