import React, { useState } from 'react';
import { Accordion, Card, Row, Col } from 'react-bootstrap';
import $ from 'jquery'; // Import jQuery






const CustomAccordion = ({ items }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };


  const handleExpandText = (index) => {
    // jQuery animation to fade in the expanded text
    $(`#expandedText_${index}`).fadeIn('100');
    // $(`#expandedText_${index}`).css({ 'display': 'inline' }); // Set display to inline after fading in
  };
  

  

  const handleCollapseText = (index) => {
    // jQuery animation to slide up the accordion item with a delay
    setTimeout(() => {
      $(`#expandedText_${index}`).fadeOut('100');
    }, 100); // 1000 milliseconds = 1 second delay
  };  

  return (


    

    <Accordion defaultActiveKey={null}>
      {items.map((item, index) => (
        <Accordion.Item key={index} eventKey={index.toString()}  onClick={() => toggleAccordion(index)} data-aos="fade-up" data-aos-duration="1500">
          <Accordion.Header>
            <Row >
              <Col xs={3}>
                <img src={item.imageSrc} alt="Accordion Icon" style={{ width: '100%' }} />
              </Col>
              <Col xs={9}>
              <h4>
  <span className="first-word">{item.title[0]}</span>
  {''}
  {item.title.split('').slice(1, -1).join('')}
  {''}
  {item.title[0].toLowerCase() === 'f' ? <span className="last-word">{item.title[item.title.length - 1]}</span> : item.title[item.title.length - 1]}
</h4>






<p>{item.initialText}<span id={`expandedText_${index}`} className="slidedown" style={{ display: 'none' }}> {item.expandedText}  </span>  </p> 


                {/* Apply animation to the expanded text */}
                
              </Col>
            </Row>
          </Accordion.Header>
          {/* Attach event handlers for expanding and collapsing text */}
          <Accordion.Body onEnter={() => handleExpandText(index)} onExit={() => handleCollapseText(index)}></Accordion.Body>

        </Accordion.Item>
      ))}
    </Accordion>
  );
};



export default CustomAccordion;


