import {React,useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Lottie from 'react-lottie';
import axios from 'axios'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { useEffect } from 'react';


import Form from 'react-bootstrap/Form';

import './Contact.css'

import sphere1 from '../../Assets/Website Assets/About/Sphere1.webp'
import sphere2 from '../../Assets/Website Assets/About/Sphere2.webp'


import InvertedRectangle from '../../Assets/Website Assets/Contact Us/InvertedRectangle.webp'
import Rocket from '../../Assets/Website Assets/Contact Us/rocket.webp'

import divsphere1 from '../../Assets/Website Assets/Contact Us/divsphere1.webp'
import divsphere2 from '../../Assets/Website Assets/Contact Us/divsphere2.webp'
import divsphere3 from '../../Assets/Website Assets/Contact Us/divsphere3.webp'


import BuildingBackground from '../../Assets/Website Assets/Contact Us/BuildingBackground.webp'

import ContactAnim  from '../../Assets/Website Assets/Animations/New/Contact Us.json'

import ContactM1 from '../../Assets/Website Assets/Contact Us/ContactM1.webp'
import ContactM2 from '../../Assets/Website Assets/Contact Us/ContactM2.webp'



export const Contact = () => {


// In your React component
useEffect(() => {
  document.title = "Contact"; // Set your desired page title here
  document.querySelector('meta[name="title"]').setAttribute("content", "Contact Us | Mobify");
  document.querySelector('meta[name="description"]').setAttribute("content", "Open the door,Join the Journey.");
  document.querySelector('meta[name="keywords"]').setAttribute("content", "mobify, mobify islamabad, game development, app development");
}, []);

const [isMobile, setIsMobile] = useState(false);




useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Adjust the width as per your needs
  };

  handleResize(); // Initial check
  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);


// Add event listener to initialize AOS when the component mounts
useEffect(() => {
  AOS.init({
    once: true, // Ensure animation only occurs once
    // offset: 400, // Adjust offset as needed to trigger animation earlier/later
    // disable: 'mobile', // Disable on mobile devices if needed
    startEvent: 'scroll', // Trigger animation on scroll event
    useClassNames: true, // Apply class names to elements
    initClassName: 'aos-init', // Class applied during initialization
    animatedClassName: 'aos-animate', // Class applied on animation
    // duration: 1000, // Duration of animations in milliseconds
    easing: 'ease', // Easing function for animations
  });
}, []);



  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ContactAnim, // Pass the imported animation data
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


  const [validated, setValidated] = useState(false);


  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    subject: '',
    contactNo: '',
    message: ''
  });

  const { fullName, email, subject, contactNo, message } = formData;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

 
  const isFormValid = () => {
    return fullName !== '' && email !== '' && subject !== '' && contactNo !== '' && message !== '';
  };




  const [isSubmitted, setIsSubmitted] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(true);

    const combinedMessage = `${message}\n\n${formData.additionalMessage}`;

    try {
      await axios.post('https://mobify.tech/send-email', formData);
      console.log('Email sent successfully');
      // Optionally, show a success message to the user
    } catch (error) {
      console.error('Error sending email:', error);
      // Optionally, show an error message to the user
    }

    setIsSubmitted(true); // Set the state to indicate submission
    setTimeout(() => setIsSubmitted(false), 3000); // Reset isSubmitted state after 3 seconds
  };
  

  return (
    <div className='contact'>
<div className="div1" style={{ background: `url(${BuildingBackground})`, backgroundPosition: 'left' }}>
        <img src={sphere1} className='sphere1 Lblock' alt="" />
          <img src={sphere2} className='sphere2 Lblock' alt="" />
          <img src={ContactM1} className='sphere1 Mblock' alt="" />
<img src={ContactM2} className='sphere2 Mblock' alt="" />


        <Container>
          <Row className='justify-content-center'>
            <Col  xs={12} md={6}  className='col' >
              <h1>Open the door,<br  className='Lblock'/> Join the Journey</h1>
            </Col>
            <Col xs={12} md={{span:6}} className='col LAnim'>
  {/* <img src={ContactBanner} className='anime' alt="" />         */}
                  <Lottie  className='Lottie_Anim' options={defaultOptions} style={{width:'49.5vw',height:'auto'}} />




</Col>

<Col sm={12} md={6} className='col MAnim'>
            <Lottie className='hero' options={defaultOptions} style={{width:'95vw',height:'auto'}} />
            {/* <Lottie className='hero' options={Anim1} style={{ height: '100%', width: '100%' }} /> */}

</Col>

            
      </Row>
        </Container>
  </div>

<div className="div2">


<img src={divsphere1} className='divsphere1 Lblock' alt="" data-aos="fade-up"
     data-aos-duration="1500"/>
<img src={divsphere2} className='divsphere2 Lblock' alt="" data-aos="fade-up"
     data-aos-duration="1500"/>
<img src={divsphere3} className='divsphere3 Lblock' alt="" data-aos="fade-up"
     data-aos-duration="1500"/>



      <Container>

    <Row>

<Col  xs={2}>
<img className='rect Lblock' src={InvertedRectangle} alt="" data-aos="fade-right"/>
<img className={`rocket Lblock ${isSubmitted ? 'submit' : 'normal'}`} src={Rocket} alt=""  data-aos="zoom-in"/>
</Col>

<Col className='formCol' md={10} data-aos="fade-up"
     data-aos-duration="1500" data-aos-delay="300">

<Col   md={{span:6, offset:3}} lg={{span:6, offset:2}} xl={{span:6, offset:2}} xxl={{span:7, offset:3}}>
      <h3>
      Connect With Us, Your<span> Thoughts Matter</span>

      </h3>
      </Col>
      <Col md={{span:10, offset:3}}  lg={{span:8, offset:2}} xl={{span:8, offset:2}} xxl={{span:7, offset:3}}>

  
      <Form validated={validated} onSubmit={handleSubmit}>
  <Row className="mb-3" data-aos="fade-up"
     data-aos-duration="1500">
  <Form.Group as={Col} md={{ span: 5 }} controlId="fullName">
          <Form.Label className="d-none d-md-block">Full Name</Form.Label>
          <Form.Control type="text" value={fullName} onChange={handleChange} placeholder={isMobile ? 'Full name' : ''}  pattern="^[^0-9]+$"/>
        </Form.Group>
        <Form.Group as={Col} md={{ span: 5, offset: 2 }} controlId="email" >
          <Form.Label className="d-none d-md-block">Email address</Form.Label>
          <Form.Control type="email" value={email} onChange={handleChange} placeholder={isMobile ? 'Email' : ''}/>
        </Form.Group>
  </Row>
  <Row className="mb-3" data-aos="fade-up"
     data-aos-duration="1500">
  <Form.Group as={Col} md={{ span: 5 }} controlId="subject">
          <Form.Label className="d-none d-md-block">Subject</Form.Label>
          <Form.Control type="text" value={subject} onChange={handleChange} pattern="^[^0-9].*$" placeholder={isMobile ? 'Subject' : ''} />
        </Form.Group>
        <Form.Group as={Col} md={{ span: 5, offset: 2 }} controlId="contactNo">
          <Form.Label className="d-none d-md-block">Contact No</Form.Label>
          <Form.Control type="tel" value={contactNo} onChange={handleChange} placeholder={isMobile ? 'Contact' : ''}     pattern="(0\d{10}|(\+92)?\d{12})" // Example: 03108661594 or 923108661594
 />
          <Form.Control.Feedback type="invalid">
    Please provide a valid contact number in the format 123-456-7890.
  </Form.Control.Feedback>
        </Form.Group>
  </Row>
  <Row className="mb-3 Lblock" data-aos="fade-up"
     data-aos-duration="1500" >
  <Form.Group as={Col} md={12} controlId="message" >
          <Form.Label className="d-none d-md-block">Message</Form.Label>
          <Form.Control type="text" value={message} onChange={handleChange} pattern="^[^0-9].*$"/>
        </Form.Group>
        <Form.Group as={Col} md={12} controlId="additionalMessage">
          <Form.Control type="text" value={formData.additionalMessage} onChange={handleChange}  pattern="^[^0-9].*$"/>
  </Form.Group>


  </Row >
  <Row className="mb-3 Mblock" data-aos="fade-up"
     data-aos-duration="1500" >

  <Form.Group as={Col} md={12} controlId="message" >
        <Form.Label className="d-none d-md-block">Message</Form.Label>
        <Form.Control as="textarea" type="text" rows={3} value={message} onChange={handleChange} pattern="^[^0-9].*$" />
        <Form.Control.Feedback type="invalid">
    Please provide a valid Message.
  </Form.Control.Feedback>
      </Form.Group>

  </Row >


  <p data-aos="fade-up"
     data-aos-duration="1500">We offer full-cycle game development, covering everything from creating concept art and visuals to development services and post-production support. </p>

<Row className='contact-row'>
  <Button data-aos="fade-up"
     data-aos-duration="1500" variant="primary" type="submit" className='hovSubmit' disabled={!isFormValid()}>
        Submit
      </Button>
      </Row>

</Form>

      </Col>

      </Col>



    </Row>

</Container>

</div>



    </div>
  )
}
