import React from 'react'
import { render } from 'react-dom';
import './Mobify.css'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Lottie from 'react-lottie';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import fjGallery from 'flickr-justified-gallery';



import MobifyLifeAnim from '../../Assets/Website Assets/Animations/New/Life In Mobify.json'

import item1 from '../../Assets/Website Assets/Life at Mobify/Desktop/item1.webp'
import item2 from '../../Assets/Website Assets/Life at Mobify/Desktop/item2.webp'
import item3 from '../../Assets/Website Assets/Life at Mobify/Desktop/item3.webp'
import item4 from '../../Assets/Website Assets/Life at Mobify/Desktop/item4.webp'
// import item5 from '../../Assets/Website Assets/Life at Mobify/Desktop/item5.webp'
import item6 from '../../Assets/Website Assets/Life at Mobify/Desktop/item6.webp'
import item7 from '../../Assets/Website Assets/Life at Mobify/Desktop/item7.webp'
// import item8 from '../../Assets/Website Assets/Life at Mobify/Desktop/item8.webp'
import item9 from '../../Assets/Website Assets/Life at Mobify/Desktop/item9.webp'
// import item10 from '../../Assets/Website Assets/Life at Mobify/Desktop/item10.webp'
// import item11 from '../../Assets/Website Assets/Life at Mobify/Desktop/item11.webp'
import item12 from '../../Assets/Website Assets/Life at Mobify/Desktop/item12.webp'
// import item13 from '../../Assets/Website Assets/Life at Mobify/Desktop/item13.webp'
import item14 from '../../Assets/Website Assets/Life at Mobify/Desktop/item14.JPG'
// import item15 from '../../Assets/Website Assets/Life at Mobify/Desktop/item15.webp'
// import item16 from '../../Assets/Website Assets/Life at Mobify/Desktop/item16.webp'
// import item17 from '../../Assets/Website Assets/Life at Mobify/Desktop/item17.webp'
// import item18 from '../../Assets/Website Assets/Life at Mobify/Desktop/item18.webp'
// import item19 from '../../Assets/Website Assets/Life at Mobify/Desktop/item19.webp'
import item20 from '../../Assets/Website Assets/Life at Mobify/Desktop/item20.webp'
import item21 from '../../Assets/Website Assets/Life at Mobify/Desktop/item21.webp'
// import item22 from '../../Assets/Website Assets/Life at Mobify/Desktop/item22.webp'
import item23 from '../../Assets/Website Assets/Life at Mobify/Desktop/item23.JPG'
// import item24 from '../../Assets/Website Assets/Life at Mobify/Desktop/item24.webp'
import item25 from '../../Assets/Website Assets/Life at Mobify/Desktop/item25.JPG'







import MobLife from '../../Assets/Website Assets/Life at Mobify/Moblife.webp'

import sphere1 from '../../Assets/Website Assets/Life at Mobify/sphere1.webp'
import sphere2 from '../../Assets/Website Assets/Life at Mobify/sphere2.webp'
import sphere3 from '../../Assets/Website Assets/Life at Mobify/sphere3.webp'
import sphere4 from '../../Assets/Website Assets/Life at Mobify/sphere4.webp'


import ContactM1 from '../../Assets/Website Assets/Contact Us/ContactM1.webp'
import ContactM2 from '../../Assets/Website Assets/Contact Us/ContactM2.webp'


import {   useMediaQuery } from '@mui/material';


function srcset(image: string, size: number, rows = 1, cols = 1) {



  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}


const aosAnimations = ['fade-up', 'fade-down', 'fade-left', 'fade-right', 'zoom-in', 'zoom-out']; // Define array of AOS animation names


export const Mobifylife = () => {

  // In your React component
useEffect(() => {
  document.title = "Life at Mobify"; // Set your desired page title here
  document.querySelector('meta[name="title"]').setAttribute("content", "Life at Mobify | Mobify");
  document.querySelector('meta[name="description"]').setAttribute("content", "Experience the Thrill of Creating Digital Wonders, Discover the moments that make us.");
  document.querySelector('meta[name="keywords"]').setAttribute("content", "mobify, mobify islamabad, game development, app development");
}, []);

  const getRandomAosAnimation = () => aosAnimations[Math.floor(Math.random() * aosAnimations.length)]; // Function to get random AOS animation

  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations in milliseconds
      easing: 'ease', // Easing function for animations
      once: true, // Whether animations should only happen once
    });
  }, []);


  useEffect(() => {
    fjGallery(document.querySelectorAll('.gallery'), {
      itemSelector: '.gallery__item',
      rowHeight: 180,
      lastRow: 'start',
      gutter: 2,
      rowHeightTolerance: 0.1,
      calculateItemsHeight: false,
    });
  }, []);



  const Anim1 = {
    loop: true,
    autoplay: true,
    animationData: MobifyLifeAnim, // Pass the imported animation data
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


  const isMobile = useMediaQuery('(max-width:600px)');
  const cols = isMobile ? 3 : 4; // Adjust the number of columns based on the device width

  return (
    <div className='mobifylife'>
      
      <div className="div1">
            <Container fluid>

              <img src={sphere1} className='sphere1 Lblock' alt="" />
              <img src={sphere2} className='sphere2 Lblock' alt="" />
              <img src={sphere3} className='sphere3 Lblock' alt="" />
              <img src={sphere4} className='sphere4 Lblock' alt="" />
              <img src={ContactM1} className='sphere1 Mblock' alt="" />
<img src={ContactM2} className='sphere2 Mblock' alt="" />

              <Row>
                  <Col className='Mblock' xs={12}>
                    <h1>Discover the moments that make us</h1>
                  </Col>

              </Row>


              <Row >
                  <Col className='d-flex justify-content-center LAnim' xs={12}>
                  <Lottie className='hero' options={Anim1} style={{width:'62vw',height:'auto'}} />
                  </Col>
                  <Col sm={12} md={6} className='col MAnim'>
            <Lottie className='hero' options={Anim1} style={{width:'88vw',height:'auto'}} />
            {/* <Lottie className='hero' options={Anim1} style={{ height: '100%', width: '100%' }} /> */}

</Col>

              </Row>
            </Container>

      </div>

    <div className="div2">
<Container>
    <h2 className='Lblock' data-aos="fade-up" >Experience the Thrill of Creating Digital Wonders, Discover the moments that make us</h2>
<div className='Lblock'>

<ImageList
      sx={{ width: '100%', height: 'auto' }}
      variant="quilted"
      cols={cols}
      rowHeight={336}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1} data-aos={getRandomAosAnimation()}>
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
    </div>





<div className='Mblock'>
      <LightGallery
        plugins={[lgZoom, lgVideo]}
        mode="lg-fade"
        pager={false}
        thumbnail={true}
        galleryId={'nature'}
        autoplayFirstVideo={false}
        elementClassNames={'gallery'}
        mobileSettings={{
          controls: false,
          showCloseIcon: false,
          download: false,
          rotate: false,
        }}
      >
        <a
          data-lg-size="1600-2400"
          data-pinterest-text="Pin it2"
          data-tweet-text="lightGallery slide  2"
          className="gallery__item"
          data-src="https://images.unsplash.com/photo-1608481337062-4093bf3ed404?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1600&q=80"
          data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@therawhunter' >Massimiliano Morosinotto </a></h4><p> Location - <a href='https://unsplash.com/s/photos/tre-cime-di-lavaredo%2C-italia'>Tre Cime di Lavaredo, Italia</a>This is the Way</p>"
        >
          <img
            className="img-responsive"
            // src="https://images.unsplash.com/photo-1608481337062-4093bf3ed404?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=240&q=80"
            src={item1}
          />
        </a>
        <a
          data-lg-size="1600-2400"
          data-pinterest-text="Pin it3"
          data-tweet-text="lightGallery slide  4"
          className="gallery__item"
          data-src="https://images.unsplash.com/photo-1605973029521-8154da591bd7?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1600&q=80"
          data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@thesaboo' >Sascha Bosshard </a></h4><p> Location - <a href='https://unsplash.com/s/photos/pizol%2C-mels%2C-schweiz'>Pizol, Mels, Schweiz</a></p>"
        >
          <img
            className="img-responsive"
            // src="https://images.unsplash.com/photo-1605973029521-8154da591bd7?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=240&q=80"
            src={item4}
          />
        </a>
        <a
          data-lg-size="1600-2398"
          data-pinterest-text="Pin it3"
          data-tweet-text="lightGallery slide  4"
          className="gallery__item"
          data-src="https://images.unsplash.com/photo-1526281216101-e55f00f0db7a?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1600&q=80"
          data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@yusufevli' >Mobify </a></h4><p> Foggy Road</p>"
        >
          <img
            className="img-responsive"
            // src="https://images.unsplash.com/photo-1526281216101-e55f00f0db7a?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=240&q=80"
            src={item3}
          />
        </a>
        <a
          data-lg-size="1600-1067"
          data-pinterest-text="Pin it3"
          data-tweet-text="lightGallery slide  4"
          className="gallery__item"
          data-src="https://images.unsplash.com/photo-1418065460487-3e41a6c84dc5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80"
          data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@flovayn' >Jay Mantri</a></h4><p>  Misty shroud over a forest</p>"
        >
          <img
            className="img-responsive"
            // src="https://images.unsplash.com/photo-1418065460487-3e41a6c84dc5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80"
            src={item2}

          />
        </a>

        <a
          data-lg-size="1600-1126"
          data-pinterest-text="Pin it3"
          data-tweet-text="lightGallery slide  4"
          className="gallery__item"
          data-src="https://images.unsplash.com/photo-1477322524744-0eece9e79640?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80"
          data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@juanster' >Juan Davila</a></h4><p>Location - <a href='Bled, Slovenia'>Bled, Slovenia</a> Wooded lake island </p>"
        >
          <img
            className="img-responsive"
            // src="https://images.unsplash.com/photo-1477322524744-0eece9e79640?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=240&q=80"
            src={item6}
          />
        </a>
        <a
          data-lg-size="1600-1063"
          data-pinterest-text="Pin it3"
          data-tweet-text="lightGallery slide  4"
          className="gallery__item"
          data-src="https://images.unsplash.com/photo-1469474968028-56623f02e42e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80"
          data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@davidmarcu' >David Marcu</a></h4><p>Location - <a href='https://unsplash.com/s/photos/ciuca%C8%99-peak%2C-romania'>Ciucaș Peak, Romania</a> Alone in the unspoilt wilderness </p>"
        >
          <img
            className="img-responsive"
            // src="https://images.unsplash.com/photo-1469474968028-56623f02e42e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80"
            src={item7}
          />
        </a>

        <a
          data-lg-size="1600-1144"
          data-pinterest-text="Pin it3"
          data-tweet-text="lightGallery slide  4"
          className="gallery__item"
          data-src="https://images.unsplash.com/photo-1476842384041-a57a4f124e2e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1600&q=80"
          data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@aaronburden' >Aaron Burden</a></h4><p>Location - <a href='https://unsplash.com/s/photos/grayling%2C-michigan%2C-united-states'>Grayling, Michigan, United States</a> Colorful trees near a lake. <a href='https://unsplash.com/photos/00QWN1J0g48'>Link</a> </p>"
        >
          <img
            className="img-responsive"
            // src="https://images.unsplash.com/photo-1476842384041-a57a4f124e2e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=240&q=80"
            src={item9}

          />
        </a>

  
        <a
          data-lg-size="1600-2400"
          data-pinterest-text="Pin it3"
          data-tweet-text="lightGallery slide  4"
          className="gallery__item"
          data-src="https://images.unsplash.com/photo-1610448721566-47369c768e70?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80"
          data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@patwhelen' >Pat Whelen</a></h4><p>Location - <a href='https://unsplash.com/s/photos/portsea-vic%2C-australia'>Portsea VIC, Australia</a> No caption <a href='https://unsplash.com/photos/EKLXDQ-dDRg'>Link</a> </p>"
        >
          <img
            className="img-responsive"
            // src="https://images.unsplash.com/photo-1610448721566-47369c768e70?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80"
            src={item12}

          />
        </a>
 
        <a
          data-lg-size="1600-2400"
          data-pinterest-text="Pin it3"
          data-tweet-text="lightGallery slide  4"
          className="gallery__item"
          data-src="https://images.unsplash.com/photo-1539678050869-2b97c7c359fd?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1600&q=80"
          data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@michaljaneck' >Michal Janek</a></h4><p>Location - <a href='https://unsplash.com/s/photos/big-sur%2C-united-states'>Big Sur, United States</a> Coast  </p>"
        >
          <img
            className="img-responsive"
            // src="https://images.unsplash.com/photo-1539678050869-2b97c7c359fd?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=240&q=80"
            src={item14}

          />
        </a>





        <a
          data-lg-size="1600-1067"
          data-pinterest-text="Pin it3"
          data-tweet-text="lightGallery slide  4"
          className="gallery__item"
          data-src="https://images.unsplash.com/photo-1471931452361-f5ff1faa15ad?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2252&q=80"
          data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@camadams' >Cam Adams</a></h4><p>Location - <a href='https://unsplash.com/s/photos/banff%2C-canada'>Banff, Canada</a> Lake along jagged mountains</p>"
        >
          <img
            className="img-responsive"
            // src="https://images.unsplash.com/photo-1471931452361-f5ff1faa15ad?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80"
            src={item20}

          />
        </a>
        <a
          data-lg-size="1600-1067"
          data-pinterest-text="Pin it3"
          data-tweet-text="lightGallery slide  4"
          className="gallery__item"
          data-src="https://images.unsplash.com/photo-1471931452361-f5ff1faa15ad?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2252&q=80"
          data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@camadams' >Cam Adams</a></h4><p>Location - <a href='https://unsplash.com/s/photos/banff%2C-canada'>Banff, Canada</a> Lake along jagged mountains</p>"
        >
          <img
            className="img-responsive"
            // src="https://images.unsplash.com/photo-1471931452361-f5ff1faa15ad?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80"
            src={item21}

          />
        </a>

        <a
          data-lg-size="1600-1067"
          data-pinterest-text="Pin it3"
          data-tweet-text="lightGallery slide  4"
          className="gallery__item"
          data-src="https://images.unsplash.com/photo-1471931452361-f5ff1faa15ad?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2252&q=80"
          data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@camadams' >Cam Adams</a></h4><p>Location - <a href='https://unsplash.com/s/photos/banff%2C-canada'>Banff, Canada</a> Lake along jagged mountains</p>"
        >
          <img
            className="img-responsive"
            // src="https://images.unsplash.com/photo-1471931452361-f5ff1faa15ad?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80"
            src={item23}

          />
        </a>

        <a
          data-lg-size="1600-1067"
          data-pinterest-text="Pin it3"
          data-tweet-text="lightGallery slide  4"
          className="gallery__item"
          data-src="https://images.unsplash.com/photo-1471931452361-f5ff1faa15ad?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2252&q=80"
          data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@camadams' >Cam Adams</a></h4><p>Location - <a href='https://unsplash.com/s/photos/banff%2C-canada'>Banff, Canada</a> Lake along jagged mountains</p>"
        >
          <img
            className="img-responsive"
            // src="https://images.unsplash.com/photo-1471931452361-f5ff1faa15ad?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80"
            src={item25}

          />
        </a>
      </LightGallery>
    </div>

    </Container>

    </div>



    </div>
  )
}



const itemData = [
  {
    img: item6,
    title: 'Mobify',
    rows: 2,
    cols: 1,
  },
  {
    img: item2,
    title: 'Mobify',
  },
  {
    img: item3,
    title: 'Mobify',
  },
  {
    img: item1,
    title: 'Mobify',
    cols: 1,
    height: 374
    
  },

  {
    img: item25,
    title: 'Mobify',
    rows: 2,
    cols: 2
  },
  {
    img: item14,
    title: 'Mobify',
    author: '@Mobify',

  },


 

  {
    img: item9,
    title: 'Mobify',
    rows: 1,
  },



  {
    img: item12,
    title: 'Mobify',

  },

  {
    img: item23,
    title: 'Mobify',
    cols: 2,
    rows:2,
  },
  {
    img: item7,
    title: 'Mobify',
  
  },



  {
    img: item21,
    title: 'Mobify',
    
  },
  {
    img: item4,
    title: 'Mobify',
    
  }, 
  {
    img: item20,
    title: 'Mobify',
    
  },

];

