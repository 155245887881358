import './Services.css';
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Lottie from 'react-lottie';
import { Carousel } from 'react-bootstrap';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import { useEffect } from 'react';


import Form from 'react-bootstrap/Form';


import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'; // Import arrow icons

import GenericCarouselServices from '../Home/GenericCarouselServices';



// Other image and asset imports
import ServicesAnim from '../../Assets/Website Assets/Animations/New/Services.json'


import question from '../../Assets/Website Assets/Services/question.webp';
import QuestionM from '../../Assets/Website Assets/Services/QuestionM.webp';
import div2img1 from '../../Assets/Website Assets/Services/Mob1.webp';
import div2img2 from '../../Assets/Website Assets/Services/Mob2.webp';
import div3img1 from '../../Assets/Website Assets/Services/Div3Img1.webp';
import div3img2 from '../../Assets/Website Assets/Services/Div3Img2.webp';
import div3img3 from '../../Assets/Website Assets/Services/Div3Img3.webp';

import pencil from '../../Assets/Website Assets/Services/pencil.webp';
import cal from '../../Assets/Website Assets/Services/cal.webp';
import mag from '../../Assets/Website Assets/Services/mag.webp';
import rocket from '../../Assets/Website Assets/Services/rocket.webp';
import settings from '../../Assets/Website Assets/Services/settings.webp';
import Sphere1 from '../../Assets/Website Assets/Services/Sphere1.webp';
import Sphere2 from '../../Assets/Website Assets/Services/Sphere2.webp';
import Sphere3 from '../../Assets/Website Assets/Services/Sphere3.webp';
import cardimg1 from '../../Assets/Website Assets/Home/Home-cardimg1.webp';
import cardimg2 from '../../Assets/Website Assets/Home/Home-cardimg2.webp';
import cardimg3 from '../../Assets/Website Assets/Home/Home-cardimg3.webp';
import cardimg4 from '../../Assets/Website Assets/Home/Home-cardimg4.webp';
import cardimg5 from '../../Assets/Website Assets/Home/Home-cardimg5.webp';
import cardimg6 from '../../Assets/Website Assets/Home/Home-cardimg6.webp';
import MobileBrilliance from '../../Assets/Website Assets/Services/MobileBrilliance.webp';
import blueprint from '../../Assets/Website Assets/Services/Blueprint.webp';
// import hero1 from '../../Assets/Website Assets/Services/Hero1.webp';
import hero1 from '../../Assets/Website Assets/Services/Hero1.webp';
import heroM from '../../Assets/Website Assets/Services/Logo-1-02 3.svg';


import LogBoy from '../../Assets/Website Assets/Animations/New/LogBoy.json'

// Component definition continues...


import SM1 from '../../Assets/Website Assets/Services/SM1.webp'

import SM2 from '../../Assets/Website Assets/Services/SM2.webp'


import Mbrilliance from '../../Assets/Website Assets/Services/Mbrilliance.webp'


import architecture from '../../Assets/Website Assets/Services/architecure.webp';
import deployment from '../../Assets/Website Assets/Services/development.webp';
import QA from '../../Assets/Website Assets/Services/QA and testing.webp';
import support from '../../Assets/Website Assets/Services/support.webp';
import deploymentA from '../../Assets/Website Assets/Services/deployment.webp';

import CustomAccordion from '../Common/CustomAccordion';


import Mcardimg1 from '../../Assets/Website Assets/Home/web development.webp';
import Mcardimg2 from '../../Assets/Website Assets/Home/app development.webp';
import Mcardimg3 from '../../Assets/Website Assets/Home/vfx.webp';
import Mcardimg4 from '../../Assets/Website Assets/Home/UI UX.webp';
import Mcardimg5 from '../../Assets/Website Assets/Home/Games development.webp';
import Mcardimg6 from '../../Assets/Website Assets/Home/Games and Publishing.webp';


export const Services = () => {



// In your React component
useEffect(() => {
  document.title = "Services"; // Set your desired page title here
  document.querySelector('meta[name="title"]').setAttribute("content", "Services | Mobify");
  document.querySelector('meta[name="description"]').setAttribute("content", "Mobify thrives on pushing the boundaries of technology and storytelling, crafting captivating experiences that leave a lasting impact.");
  document.querySelector('meta[name="keywords"]').setAttribute("content", "mobify, mobify islamabad, game development, app development");
}, []);


  const [index, setIndex] = useState(0); // State to track the current index of the Carousel

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };


  const accordionItems = [
    {
      imageSrc: architecture,
      title: 'Architecture & Design',
      initialText: 'Defining the softwares blueprint for ',
      expandedText: 'functionality, scalability, and security.',
    },
    {
      imageSrc: deployment,
      title: 'Development',
      initialText: 'Building the product based on',
      expandedText: ' the defined architecture and design.',
    },
    {
      imageSrc: QA,
      title: 'QA & Testing',
      initialText: 'Ensuring the software functions as ',
      expandedText: 'intended and meets quality standards.',
    },
    {
      imageSrc: deploymentA,
      title: 'Deployment & Launch',
      initialText: 'Releasing the product to its intended ',
      expandedText: 'environment and users',
    },
    {
      imageSrc: support,
      title: 'Support & Maintenance',
      initialText: 'Fixing bugs, addressing user issues, ',
      expandedText: 'and updating the product after launch.',
    },

    // Add more items as needed
  ];











// Add event listener to initialize AOS when the component mounts
useEffect(() => {
  AOS.init({
    once: true, // Ensure animation only occurs once
    offset: 270, // Adjust offset as needed to trigger animation earlier/later
    // disable: 'mobile', // Disable on mobile devices if needed
    startEvent: 'scroll', // Trigger animation on scroll event
    useClassNames: true, // Apply class names to elements
    initClassName: 'aos-init', // Class applied during initialization
    animatedClassName: 'aos-animate', // Class applied on animation
    // duration: 1000, // Duration of animations in milliseconds
    easing: 'ease', // Easing function for animations
  });
}, []);


  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [showModal6, setShowModal6] = useState(false);



  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const handleModalShow2 = () => setShowModal2(true);
  const handleModalClose2 = () => setShowModal2(false);
  

  const handleModalShow3 = () => setShowModal3(true);
  const handleModalClose3 = () => setShowModal3(false);

  const handleModalShow4 = () => setShowModal4(true);
  const handleModalClose4 = () => setShowModal4(false);


  const handleModalShow5 = () => setShowModal5(true);
  const handleModalClose5 = () => setShowModal5(false);


  const handleModalShow6 = () => setShowModal6(true);
  const handleModalClose6 = () => setShowModal6(false);


  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    subject: '',
    contactNo: '',
    message: ''
  });

  const { fullName, email, subject, contactNo, message } = formData;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

 
  const isFormValid = () => {
    return fullName !== '' && email !== '' && subject !== '' && contactNo !== '' && message !== '';
  };




  const [isSubmitted, setIsSubmitted] = useState(false);


  const [validated, setValidated] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(true);

    const combinedMessage = `${message}\n\n${formData.additionalMessage}`;

    try {
      await axios.post('https://mobify.tech/send-email', formData);
      console.log('Email sent successfully');
      // Optionally, show a success message to the user
    } catch (error) {
      console.error('Error sending email:', error);
      // Optionally, show an error message to the user
    }

    setIsSubmitted(true); // Set the state to indicate submission
    setTimeout(() => setIsSubmitted(false), 3000); // Reset isSubmitted state after 3 seconds
  };


  // Pop Up Form

  const [show, setShow] = useState(false);

  const Anim1 = {
    loop: true,
    autoplay: true,
    animationData: ServicesAnim, // Pass the imported animation data
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const LogAnim = {
    loop: true,
    autoplay: true,
    animationData: LogBoy, // Pass the imported animation data
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Handle form submission logic here
  //   handleClose(); // Close the modal after form submission
  // };


  const carouselItems = [
    {
      image: Mcardimg1,
      title: 'Web Development',
      text: 'Concept, Code, Click: Developing Seamless Web Experiences',
      onClick: handleModalShow,
    },
    {
      image: Mcardimg2,
      title: 'Apps Development',
      text: 'Touch, Tap, Transform: Crafting Mobile Apps That Resonate',
      onClick: handleModalShow2,
    },
    {
      image: Mcardimg3,
      title: 'Games Development',
      text: 'Quests and Codes: Engineering Your Gaming dreams into Reality',
      onClick: handleModalShow3,
    },
    {
      image: Mcardimg4,
      title: 'UI/UX Designing',
      text: 'Aesthetic Ingenuity (UI/UX)',
      onClick: handleModalShow4
    },
    {
      image: Mcardimg5,
      title:'Games and Apps Publishing',
      text: 'Reach The Stars: Publish Your Game App with Us',
      onClick: handleModalShow5
    },
    {
      image: Mcardimg6,
      title: 'VFX',
      text: 'Art of Visual Magic: Transformative VFX Services for Enchanting Visuals',
      onClick: handleModalShow6
    }
  ];


  

  return (
    <div className='services'>
      
      <div className="div1">
        <div className="mycontainer">

        <img src={Sphere1} className='img1 Lblock' alt="" />
        <img src={Sphere2} className='img2 Lblock' alt="" />
        <img src={Sphere3} className='img3 Lblock' alt="" />

        <img src={SM1} alt="" className='SM1 Mblock' />
        <img src={SM2} alt="" className='SM2 Mblock' />

        </div>

        <Container>
          <Row className='servicemargin justify-content-center'>  
            <Col  xs={12} md={6} className='col s-img Lblock portfoliologo'>
            <img src={hero1} className='anime service-img' loading="eager" alt="" />        
            </Col>
            <Col  xs={12} md={5} className='col s-img Mblock'>
            <img src={heroM} className='anime service-img' loading="eager" alt="" />        
            </Col>



   


            <Col sm={12} md={6} lg={6} className='col LAnim'>
            <Lottie className='hero' options={Anim1} loading="lazy" style={{width:'44vw', height:'auto'}} />
            {/* <Lottie className='hero' options={Anim1} style={{ height: '100%', width: '100%' }} /> */}

</Col>
<Col sm={12} md={6} className='col MAnim'>
            <Lottie className='hero' options={Anim1} style={{width:'96vw', height:'auto'}} />
            {/* <Lottie className='hero' options={Anim1} style={{ height: '100%', width: '100%' }} /> */}

</Col>
            
      </Row>
        </Container>
  </div>

  {/* <div className="div2"   style={{backgroundImage: `url(${div2back})`}}>  */}
  <div className="div2"   > 

        <img src={div2img1} className='img1 Lblock' alt="" data-aos="fade-up"
     data-aos-duration="1500"/>
        <img src={div2img2} className='img2 Lblock' alt="" data-aos="zoom-in-up"/>
        <Container>
        <Row className='Lblock'>
  <Col xs={12} data-aos="fade-up"
     data-aos-duration="1500"> {/* Use xs={12} to span the full width on extra small screens */}
    <h1>Why MOBIFY?</h1>
  </Col>
  <Col xs={12} md={{span:5}} className='col' data-aos="fade-up"
     data-aos-duration="1500">
  <h2>360 Degree Approach</h2>
  <h3>At Mobify, We're your partners in success, taking a 360-degree approach to meet your needs. From planning to deployment, we're with you every step, analyzing challenges, and delivering tailored solutions. Contact us now to experience the difference.
</h3>
  </Col>
  <Col xs={12} md={{span:5, offset:1}} className='col question-mark' data-aos="fade-up"
     data-aos-duration="1500">
  <img src={question} alt="" />
  <h2>Innovation at the Core</h2>
  <h3>At Mobify, we don’t settle for the status quo. We are always looking for new ways to improve our solutions, exploring new technologies, methods, and ideas to deliver more value to you. We are not just a digital solution company. We are a team of innovators who are passionate that put innovation at the core of everything we do.
</h3>
  </Col>

  <Col xs={12} md={{span:5}} className='col' data-aos="fade-up"
     data-aos-duration="1500">
  <h2>Customer-Centric Approach</h2>
  <h3>Your satisfaction is our success. We're dedicated to providing you with the highest level of service and support, from initial consultation to ongoing maintenance. Your success is our mission.
</h3>
  </Col>

  <Col xs={12} md={{span:5, offset:1}} className='col' data-aos="fade-up"
     data-aos-duration="1500">
  <h2>Expertise and Experience</h2>
  <h3>With 10 years of experience under our belt, we bring a wealth of expertise to the table. From software development to project management, our team has the skills and know-how to deliver exceptional results.
</h3>
  </Col>





  
</Row>



<Col className='Mblock' xs={12} data-aos="fade-up"
     data-aos-duration="1500"> {/* Use xs={12} to span the full width on extra small screens */}
    <h1>Why MOBIFY?</h1>
  </Col>



<>
      <Carousel
        className='QuestionCarousel Mblock'
        activeIndex={index}
        onSelect={handleSelect}
        nextIcon={<BsChevronRight className="carousel-control-icon" />}
        prevIcon={<BsChevronLeft className="carousel-control-icon" />}
        indicators={false}
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <Carousel.Item>
          <Col xs={12} lg={{ span: 5 }} className='col'>
            <h2>360 Degree Approach</h2>
            <h3>At Mobify, We're your partners in success, taking a 360-degree approach to meet your needs. From planning to deployment, we're with you every step, analyzing challenges, and delivering tailored solutions. Contact us now to experience the difference.</h3>
          </Col>
        </Carousel.Item>

        <Carousel.Item>
          <Col xs={12} lg={{ span: 5, offset: 2 }} className='col'>
            <h2>Innovation at the Core</h2>
            <h3>At Mobify, we don’t settle for the status quo. We are always looking for new ways to improve our solutions, exploring new technologies, methods, and ideas to deliver more value to you. We are not just a digital solution company. We are a team of innovators who are passionate that put innovation at the core of everything we do.</h3>
          </Col>
        </Carousel.Item>

        <Carousel.Item>
          <Col xs={12} lg={6} className='col'>
            <h2>Customer-Centric Approach</h2>
            <h3>Your satisfaction is our success. We're dedicated to providing you with the highest level of service and support, from initial consultation to ongoing maintenance. Your success is our mission.</h3>
          </Col>
        </Carousel.Item>

        <Carousel.Item>
          <Col xs={12} lg={6} className='col'>
            <h2>Expertise and Experience</h2>
            <h3>With 10 years of experience under our belt, we bring a wealth of expertise to the table. From software development to project management, our team has the skills and know-how to deliver exceptional results.</h3>
          </Col>
        </Carousel.Item>
      </Carousel>

      {/* Render the image only when the third slide is active */}
      {index === 1 && (
        <img src={QuestionM} className='question Mblock' alt="" data-aos="fade-up" data-aos-duration="1500" />
      )}
    </>



        </Container>
  </div>



  <div className="div3"> 
        <img src={div3img1} className='img1 Lblock' alt="" data-aos="zoom-in"  />
        <img src={div3img2} className='img2 Lblock' alt="" data-aos="zoom-in" />
        <img src={div3img3} className='img3 Lblock' alt="" data-aos="zoom-in" />
        <Container>
        <Row>
  <Col xs={12} data-aos="fade-up"
     data-aos-duration="1500" className='Lblock'> {/* Use xs={12} to span the full width on extra small screens */}
    <h2>Blueprints to Brilliance</h2>
  </Col>
  
  <Col xs={12} data-aos="fade-up"
     data-aos-duration="1500" className='Mblock'> {/* Use xs={12} to span the full width on extra small screens */}
    <h2 style={{width:'83%',margin:'0 auto'}}>Blueprints to Brilliance</h2>
  </Col>

  
  <Row className='blueprints Lblock' data-aos="fade-up"
     data-aos-duration="1500">
  
  
  <img src={blueprint} alt=""  className='blueprint Lblock'/>
  <img src={pencil} alt="" className='one icon-images Lblock' />
  <img src={cal} alt="" className='two icon-images Lblock' />  
  <img src={mag} alt="" className='three icon-images Lblock'  />
  <img src={rocket} alt="" className='four icon-images Lblock' />
  <img src={settings} alt="" className='five icon-images Lblock' />

  <img src={MobileBrilliance} className='MobileBrilliance Lblock' alt="" />

    <img src={Mbrilliance} className='Mbrilliance Mblock' alt="" />

  <Col md={{ span: 3, offset: 2 }} className='Lblock'><h3>Development</h3>
    <h4>Building the product based on the defined architecture and design.</h4>
   </Col>
  <Col md={{ span: 3, offset: 2 }} className='Lblock'><h3>Deployment & Launch</h3>
  <h4>Releasing the product to its intended environment and users.</h4>

    
    
    </Col>
  
  
    <Col md={{ span: 2}}>
   </Col>




<Col md={{ span: 2, offset: 0 }} className='mt-5 w20 Lblock'><h3>Architecture & Design</h3>
    <h4>Defining the software's blueprint for functionality, scalability, and security.</h4>
</Col>

<Col md={{ span: 2, offset: 3 }} className='mt-5 w20 ml23 Lblock'><h3>QA & Testing</h3>
    <h4>Ensuring the software functions as intended and meets quality standards.</h4>
</Col>
<Col md={{ span: 2, offset: 2 }} className='mt-5 w20 Lblock'> <h3>Support & Maintenance</h3>
    <h4>Fixing bugs, addressing user issues, and updating the product after launch.</h4>
</Col>

<Row className='blueprintRow'>
  


<Col xs={{ span: 5 }} className='Mblock'><h3>Architecture & Design</h3>
   </Col>

   <Col xs={{ span: 5,  offset:6 }} className='Mblock mt-4'><h3>Development</h3>
   </Col>

   <Col xs={{ span: 5 }} className='Mblock mt-4'><h3>QA & Testing</h3>
   </Col>

   <Col xs={{ span: 5,  offset:6 }} className='Mblock mt-4'><h3>Support & Maintenance</h3>
   </Col>

   <Col xs={{ span: 5}} className='Mblock mt-4'><h3>Deployment & Launch</h3>
   </Col>
   </Row>

  </Row>
  
<div className='MobAccord Mblock'> 

  <CustomAccordion items={accordionItems} />

  </div>

  
</Row>

        </Container>
  </div>



  <div className="div4 glimpse">

<img src={div3img1} className='img1 Lblock' alt="" data-aos="zoom-in" />
<img src={div3img2} className='img2 Lblock' alt="" data-aos="zoom-in" />

<Container>



  <Row className='Lblock'>
    <Col md={12}>
      <h2 style={{padding:'0 0 4rem 0'}} data-aos="fade-up"
     data-aos-duration="1500" data-aos-delay="50">Glimpse of what we offer</h2>
    </Col>
    <Col sm={12} md={4} className='col' data-aos="fade-up"
     data-aos-duration="1000">
              <Card>
                <Card.Body>
                  <Row>
                    <Col lg={3} md ={4}>
                      <Card.Img variant="top" src={cardimg1} />
                    </Col>
                    <Col lg={8} md ={8}>
                      <div>
                        <Card.Title>Web Development</Card.Title>
                      </div>
                    </Col>
                  </Row>
                  <Card.Text>
                  Concept, Code, Click: Developing Seamless Web Experiences
                  </Card.Text >
                  <hr className='card-hr' />

                  <Button variant="primary" onClick={handleModalShow}>Read More</Button>
                </Card.Body >
              </Card >
            </Col >

  <Modal  show={showModal} onHide={handleModalClose}          dialogClassName="modal-90w" className='custom' centered
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header closeButton>
          <Modal.Title>Web Development</Modal.Title>
        </Modal.Header>
        <Modal.Body >Ignite your online presence with our world-class web development services. Our expert team combines creativity and technical prowess to build websites that drive results on a global scale. Contact us now and let's build something extraordinary together</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal2} onHide={handleModalClose2} centered>
        <Modal.Header closeButton>
          <Modal.Title>App Development</Modal.Title>
        </Modal.Header>
        <Modal.Body>Step into the realm of possibility with Mobify, With our roots deeply embedded in mobile applications development, we've honed our craft to perfection. Now, as we extend our services to clients, we bring a wealth of experience and a passion for innovation to every project. From concept to launch, we're with you every step of the way, transforming ideas into impactful solutions. Ready to elevate your digital presence and exceed expectations? Join forces with us today and let's embark on a journey of success.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose2}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal3} onHide={handleModalClose3} centered>
        <Modal.Header closeButton>
          <Modal.Title>Games Development</Modal.Title>
        </Modal.Header>
        <Modal.Body>Mobify takes pride in its process of Game development and its results, Celebrated for our mastery in mobile gaming and app development, we are initiating to introduce our detailed and part-wise mobile game development, game design, and publishing services to national and international clients. Whether it's iOS or Android platforms, or harnessing the dynamic Unity engine, we craft genuine solutions to elevate your gaming vision. Ready to level up your gaming strategy? Connect with us today and let's shape the future of gaming together."</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose3}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal4} onHide={handleModalClose4} centered>
        <Modal.Header closeButton>
          <Modal.Title>UI/UX Development</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you looking for a reliable and professional UI/UX Design developer company? We are a UI/UX Design developer company that can help you achieve your goals. We have a team of experts who can design and develop attractive, intuitive, and responsive interfaces for any platform. We can also help you optimize your user experience, and boost your brand identity. Whether you need a simple landing page or a complex e-commerce site, we can handle it. Contact us today and let us transform your vision into reality, one pixel at a time.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose4}>Close</Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showModal5} onHide={handleModalClose5} centered>
        <Modal.Header closeButton>
          <Modal.Title>Games and Apps Publishing</Modal.Title>
        </Modal.Header>
        <Modal.Body>Picture this: you've poured your heart and soul into developing your game or app, but now what? That's where our Marketing Team swoops in with our bag of tricks. We will give wings to your Products as we specialize in helping game and app developers reach a wider audience and maximize their impact in the digital marketplace. Whether you're an indie studio or a mobile app creator, our platform offers global distribution and increased visibility for your creations. With us, Reach Millions of Users</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose5}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal6} onHide={handleModalClose6} centered>
        <Modal.Header closeButton>
          <Modal.Title>VFX</Modal.Title>
        </Modal.Header>
        <Modal.Body>We will give voice to your Content it deserves. Enhance your digital content with our cutting-edge VFX services tailored for clients. With our innovative solutions and customized approach, we transform your content into engaging and memorable experiences. Whether you're creating promotional videos, commercials, or corporate presentations, our VFX services add a touch of professionalism and creativity to your projects.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose6}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Col sm={12} md={4} className='col' data-aos="fade-up"
     data-aos-duration="1000">
              <Card >
                <Card.Body>
                  <Row>
                    <Col lg={3} md ={4}>
                      <Card.Img variant="top" src={cardimg2} />
                    </Col>
                    <Col lg={8} md ={8}>
                      <div>
                        <Card.Title>Apps Development</Card.Title>
                      </div>
                    </Col>
                  </Row >
                  <Card.Text>
                  Touch, Tap, Transform: Crafting Mobile Apps That Resonate
                  </Card.Text >
                  <hr className='card-hr' />

                   <Button variant="primary" onClick={handleModalShow2}>Read More</Button>
                </Card.Body >
              </Card >
            </Col >

            <Col sm={12} md={4} className='col' data-aos="fade-up"
     data-aos-duration="1000">
              <Card >
                <Card.Body>
                  <Row>
                    <Col lg={3} md ={4}>
                      <Card.Img variant="top" src={cardimg3} />
                    </Col>
                    <Col lg={8} md ={8}>
                      <div>
                        <Card.Title>Games Development</Card.Title>
                      </div>
                    </Col>
                  </Row >
                  <Card.Text>
                  Quests and Codes: Engineering Your Gaming dreams into Reality
                  </Card.Text >
                  <hr className='card-hr' />

                  <Button variant="primary" onClick={handleModalShow3}>Read More</Button>
                </Card.Body >
              </Card >
            </Col >


            <Col sm={12} md={4} className='col' data-aos="fade-up"
     data-aos-duration="1000">
              <Card >
                <Card.Body>
                  <Row>
                    <Col lg={3} md ={4}>
                      <Card.Img variant="top" src={cardimg4} />
                    </Col>
                    <Col lg={8} md ={8}>
                      <div>
                        <Card.Title>UI/UX Designing</Card.Title>
                      </div>
                    </Col>
                  </Row >
                  <Card.Text>
                  Aesthetic Ingenuity (UI/UX)
                  </Card.Text >
                  <hr className='card-hr' />

                 <Button variant="primary" onClick={handleModalShow4}>Read More</Button>
                </Card.Body >
              </Card >
            </Col >




            <Col sm={12} md={4} className='col' data-aos="fade-up"
     data-aos-duration="1000">
              <Card >
                <Card.Body>
                  <Row>
                    <Col lg={3} md ={4}>
                      <Card.Img variant="top" src={cardimg5} />
                    </Col>
                    <Col lg={8} md ={8}>
                      <div>
                        <Card.Title>Games and Apps Publishing</Card.Title>
                      </div>
                    </Col>
                  </Row>
                  <Card.Text>
                  Reach The Stars: Publish Your Game/App with Us
                  </Card.Text>
                  <hr className='card-hr' />

                  <Button variant="primary" onClick={handleModalShow4}>Read More</Button>
                </Card.Body>
              </Card>
            </Col>






            <Col sm={12} md={4} className='col' data-aos="fade-up"
     data-aos-duration="1000">
              <Card >
                <Card.Body>
                  <Row>
                    <Col lg={3} md ={4}>
                      <Card.Img variant="top" src={cardimg6} />
                    </Col>
                    <Col lg={8} md ={8}>
                      <div>
                        <Card.Title>VFX</Card.Title>
                      </div>
                    </Col>
                  </Row >
                  <Card.Text>
                  Art of Visual Magic: Transformative VFX Services for Enchanting Visuals
                  </Card.Text >
                  <hr className='card-hr' />
                  <Button variant="primary" onClick={handleModalShow6}>Read More</Button>
                </Card.Body >
              </Card >
            </Col >
  </Row >


<div className='Mblock' data-aos="fade-up"
     data-aos-duration="1500">
    <h2 >Glimpse of what we offer</h2>

  <GenericCarouselServices items={carouselItems} />

  </div>



</Container >

</div >


<div className='div5'>
<Container>
      <Row>
        <Col md={6} data-aos="fade-up"
     data-aos-duration="1500" data-aos-delay="100"><h1>Lets Work together</h1>   <h3>We offer full-cycle game development, covering everything from creating concept art and visuals to development services and post-production support.</h3>
        <Button variant="primary" onClick={() => setShow(true)}>
        Get Started Now
      </Button>        
        </Col>
        <Col md={5} data-aos="fade-up"
     data-aos-duration="1500" data-aos-delay="100" className='Lblock'>
          
          <Lottie className='clockAnim' options={LogAnim} style={{ height: 'auto', width: '31vw' }}/>
          </Col>

          <Col md={5} data-aos="fade-up"
     data-aos-duration="1500" data-aos-delay="100" className='Mblock'>
          
          <Lottie className='clockAnim' options={LogAnim} style={{ height: 'auto', width: '91vw' }}/>
          </Col>


          
      </Row>

      <Modal
  show={show}
  onHide={() => setShow(false)}
  dialogClassName="modal-90w"
  aria-labelledby="example-custom-modal-styling-title"
  className='serviceContact' centered
>
  <Modal.Header closeButton>
    <Modal.Title id="example-custom-modal-styling-title">
      Contact Us
    </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3 R1">
        <Form.Group as={Col} md={{ span: 6 }} controlId="fullName">
          <Form.Control type="text" value={fullName} onChange={handleChange} placeholder='Full Name' pattern="^[^0-9]+$"/>
        </Form.Group>
        <Form.Group as={Col} md={{ span: 6, }} controlId="email">
          <Form.Control type="email" value={email} onChange={handleChange}  placeholder='Email'/>
        </Form.Group>
      </Row>
      <Row className="mb-3 R1">
        <Form.Group as={Col} md={{ span: 6 }} controlId="subject">
          <Form.Control type="text" value={subject} onChange={handleChange} placeholder='Subject' pattern="^[^0-9].*$"/>
        </Form.Group>
        <Form.Group as={Col} md={{ span: 6}} controlId="contactNo">
          <Form.Control type="tel" value={contactNo} onChange={handleChange} placeholder='Phone' pattern="(0\d{10}|(\+92)?\d{12})"/>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md={12} controlId="message">
          <Form.Control type="text" value={message} onChange={handleChange} placeholder='Message' pattern="^[^0-9].*$"/>
        </Form.Group>

        <Form.Group as={Col} md={12} controlId="additionalMessage">
          <Form.Control type="text" value={formData.additionalMessage} onChange={handleChange} pattern="^[^0-9].*$" />
        </Form.Group>
      </Row>
      <p>We offer full-cycle game development, covering everything from creating concept art and visuals to development services and post-production support. </p>
      <Button variant="primary" type="submit" className='hovSubmit mybutton' disabled={!isFormValid()}>
        Submit
      </Button>
    </Form>
  </Modal.Body>
</Modal>




    </Container>


</div>

    </div>
  )
}
