import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/esm/Button';
import Logo from '../../Assets/Images/logo.webp'
import MobLogo from '../../Assets/Images/MobLogo.webp'

import './Header.css'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import Lottie from 'react-lottie';


// import Message1 from '../../Assets/Website Assets/Animations/New/Message1.json'
import Message1 from '../../Assets/Website Assets/Animations/New/Chat smile.json'



export const Header = () => {
  
  const styleObject = { width: '20vw', height: '8vh' };


  
  const [expanded, setExpanded] = useState(false);


  const handleToggle = () => {
    setExpanded(!expanded);
  };
  

  const handleCloseDropdown = () => {
    setExpanded(false);
};

const handleDropdownItemClick = () => {
  setExpanded(false);
};


const Anim1 = {
  loop: true,
  autoplay: true,
  animationData: Message1, // Pass the imported animation data
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

  
  return (
      <div className='header'>
      <Navbar className='Lblock' expand="md" expanded={expanded} variant="light">
        <Container>
          <Navbar.Brand as={Link} to="/"><img src={Logo} alt="Logo" className='Lblock' /></Navbar.Brand>



          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
          <Navbar.Collapse id="basic-navbar-nav">
<Nav>
  <NavDropdown title="Portfolio" id="basic-nav-dropdown">
    <NavDropdown.Item  as={NavLink} to="/games" className='drop-item' onClick={handleDropdownItemClick}>Games</NavDropdown.Item>
    <NavDropdown.Item as={NavLink} to="/Apps" className='drop-item' onClick={handleDropdownItemClick}>Apps</NavDropdown.Item>
  </NavDropdown>
  <Nav.Link as={NavLink} to="/services" onClick={handleCloseDropdown}>Services</Nav.Link>
  <Nav.Link as={NavLink} to="/about" onClick={handleCloseDropdown}>About</Nav.Link>
  <Nav.Link as={NavLink} to="/career" onClick={handleCloseDropdown}>Career</Nav.Link>
  <Nav.Link as={NavLink} to="/lifeatmobify" onClick={handleCloseDropdown}>Life at Mobify</Nav.Link>
</Nav>



            <Nav>
              <Button className='header-button' as={Link} to="/contact" onClick={handleCloseDropdown}>Contact Us</Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Navbar className='Mblock' expand="lg" expanded={expanded} variant="light">
        <Container>
          <Navbar.Brand as={Link} to="/"><img src={MobLogo} alt="Logo" className='Mblock'/></Navbar.Brand>



<Nav>

<Lottie className='hero' options={Anim1} style={styleObject} />


</Nav>



        </Container>
      </Navbar>




    </div>








    )


  
}



// Get all navigation items
var navItems = document.querySelectorAll('.nav-item');

// Loop through each navigation item
navItems.forEach(function(item) {
  // Add event listener for click event
  item.addEventListener('click', function() {
    // Remove active class from all navigation items
    navItems.forEach(function(navItem) {
      navItem.classList.remove('active');
    });
    // Add active class to the clicked navigation item
    item.classList.add('active');
  });
});