import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Lottie from 'react-lottie';
import { Carousel } from 'react-bootstrap';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'; // Import arrow icons
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


import './Portfolio.css'


// import LogoAppsM from '../../Assets/Website Assets/Apps/AppsTextM.webp'
import LogoAppsM from '../../Assets/Website Assets/Apps/AppsTextM.webp'
import LogoSvg from '../../Assets/Website Assets/Apps/Apps-01 1.svg'





import App1 from '../../Assets/Website Assets/Apps/App1.webp'
import App2 from '../../Assets/Website Assets/Apps/App2.webp'
import App3 from '../../Assets/Website Assets/Apps/App3.webp'

import App4 from '../../Assets/Website Assets/Apps/App4.webp'
import App5 from '../../Assets/Website Assets/Apps/App5.webp'
import App6 from '../../Assets/Website Assets/Apps/App6.webp'


import App7 from '../../Assets/Website Assets/Apps/App7.webp'
import App8 from '../../Assets/Website Assets/Apps/App8.webp'
import App9 from '../../Assets/Website Assets/Apps/App9.webp'


import AppAnim from '../../Assets/Website Assets/Animations/New/Apps new.json'

import bannerImage from '../../Assets/Website Assets/Games/Games Main Banner.webp'; // Replace 'games-main-banner.jpg' with your actual image file



import Mbanner from '../../Assets/Website Assets/Games/GamesBackM.webp'


// Div2 Images
import Div2Img1 from '../../Assets/Website Assets/Apps/Sphere1.webp'
import Div2Img2 from '../../Assets/Website Assets/Apps/Sphere2.webp'
import Div2Img3 from '../../Assets/Website Assets/Apps/Sphere3.webp'
import Div2Img4 from '../../Assets/Website Assets/Apps/Sphere4.webp'


import obj1 from '../../Assets/Website Assets/Apps/obj1.webp'
import obj2 from '../../Assets/Website Assets/Apps/obj2.webp'
import obj3 from '../../Assets/Website Assets/Apps/obj3.webp'

import play from '../../Assets/Website Assets/Home/Play.webp'
import ios from '../../Assets/Website Assets/Home/ios1.webp'

import AsNavFor from './PortfolioSlider';


//images
import video from '../../Assets/Website Assets/Apps/Apps//New/Video-Player-All-in-One-VPlay 2.webp'
import love from '../../Assets/Website Assets/Apps/Apps//New/image 80 (1) copy 1.webp'
import flora from '../../Assets/Website Assets/Apps/Apps//New/image 80 2 1.webp'
import Collage from '../../Assets/Website Assets/Apps/Apps//New/image 80 3 1.webp'
import TrueLove from '../../Assets/Website Assets/Apps/Apps//New/image 80 4 1.webp'
import Pics from '../../Assets/Website Assets/Apps/Apps//New/image 80 5 1.webp'
import QR from '../../Assets/Website Assets/Apps/Apps//New/image 80 6 1.webp'
import Sunglasses from '../../Assets/Website Assets/Apps/Apps//New/image 80 7 1.webp'
import Profile from '../../Assets/Website Assets/Apps/Apps//New/image 80 8 1.webp'
import ClickCut from '../../Assets/Website Assets/Apps/Apps/New/image 80.webp'








//icon

import vicon from '../../Assets/Website Assets/Apps/Apps/New/image 112.webp'
import licon from '../../Assets/Website Assets/Apps/Apps/New/image 60-1.webp'
import ficon from '../../Assets/Website Assets/Apps/Apps/New/image 59-1.webp'
import cicon from '../../Assets/Website Assets/Apps/Apps/New/image 65-1.webp'
import itl from '../../Assets/Website Assets/Apps/Apps/New/image 126.webp'
import iPics from '../../Assets/Website Assets/Apps/Apps/New/image 64-1.webp'
import iQR from '../../Assets/Website Assets/Apps/Apps/New/image 71-1.webp'
import iSunglasses from '../../Assets/Website Assets/Apps/Apps//New/image 62-1.webp'
import iProfile from '../../Assets/Website Assets/Apps/Apps/New/image 128.webp'
import iClickCut from '../../Assets/Website Assets/Apps/Apps/New/Group 346.webp'






//thumbnails
import TV from '../../Assets/Website Assets/Apps/Apps/New/image 123.webp'
import TL from '../../Assets/Website Assets/Apps/Apps/New/image 60.webp'
import TF from '../../Assets/Website Assets/Apps/Apps/New/image 59.webp'
import TC from '../../Assets/Website Assets/Apps/Apps/New/image 65.webp'
import Ttl from '../../Assets/Website Assets/Apps/Apps/New/image 127.webp'
import Tpics from '../../Assets/Website Assets/Apps/Apps/New/image 64.webp'
import TQR from '../../Assets/Website Assets/Apps/Apps/New/image 71.webp'
import TSunglasses from '../../Assets/Website Assets/Apps/Apps//New/image 62.webp'
import TProfile from '../../Assets/Website Assets/Apps/Apps//New/image T.webp'
import TClickCut from '../../Assets/Website Assets/Apps/Apps//New/image 69.webp'




// Column images
import Bookimage from '../../Assets/Website Assets/Apps/Apps/New/image 54.webp'
import Loveimage from '../../Assets/Website Assets/Apps/Apps/New/image 55.webp'
import Bridalimage from '../../Assets/Website Assets/Apps/Apps/New/image 56.webp'
import Cakeimage from '../../Assets/Website Assets/Apps/Apps/New/image 57.webp'
import Framimage from '../../Assets/Website Assets/Apps/Apps/New/image 125.webp'





// Downloads icon

import DownloadIcon from '../../Assets/Website Assets/Games/Games/DownloadIcon.webp'



const images = [{
  image: App1,
  button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudio.android.love.animated.photo.frame.editor',
  
},
{
  image: App2,
  button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudio.books.photo.frame.collage',
  
},
{
  image: App3,
  button1Link: 'https://play.google.com/store/apps/details?id=com.frameme.photoeditor.collagemaker.effects',
  
},
{
  image: App4,
  button1Link: 'https://play.google.com/store/apps/details?id=com.xilli.collagemaker.photoeffects.photoeditor',
  
},
{
  image: App5,
  button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudio.birthdaycake.photoframe&hl=en', // to change photo on cake link
  
},
{
  image: App6,
  button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudio.romantic.love.photoframe',
  
},
{
  image: App7,
  button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudios.allformate.videoplay.hdvideoplayer',
  
},
{
  image: App8,
  button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudio.secure.swift.vpnproxy.android',
  
},
{
  image: App9,
  button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudio.romantic.love.photoframe&hl=en',
  
},
]; // Define your image paths array here

const mobileimages = [
  {
    image: video,
    icon: vicon,
    thumbnail: TV,
    title: 'Video Player All in One',
    thumbnailtext: 'Video Player All in One',
    Downloads: '1 M+',
    button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudios.allformate.videoplay.hdvideoplayer',


  },


  {
    image: love,
    icon:licon,
    thumbnail: TL,
    Downloads: '100 K+',
    title: 'Love Photo Frame',
    thumbnailtext: 'Love Photo Frame Collage',
    button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudio.love.photoframes',


  },
  {
    image: flora,
    icon: ficon,
    thumbnail: TF,
    Downloads: '100 K+',
    title: 'Flora Garden Photo Frame',
    thumbnailtext: 'Flora Garden Photo Frame',
    button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudio.garden.photoframe',


  },
  {
    image: Collage,
    icon:cicon,
    thumbnail: TC,
    Downloads: '1 M+',
    title: 'Collage Maker Photo Editor',
    thumbnailtext: 'Collage Maker Photo Editor',
    button1Link: 'https://play.google.com/store/apps/details?id=com.xilli.collagemaker.photoeffects.photoeditor',


  },
  {
    image: TrueLove,
    icon:itl,
    thumbnail: Ttl,
    Downloads: '10 M+',
    title: 'True Love Frames',
    thumbnailtext: 'True Love Frames',
    button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudio.romantic.love.photoframe',


  },

  {
    image: Pics,
    icon: iPics,
    thumbnail: Tpics,
    Downloads: '100 M+',
    title: 'PicsCut Background',
    thumbnailtext: 'PicsCut Background',
    button1Link: 'https://play.google.com/store/apps/details?id=com.vrchilli.photo_background.eraser.effect',


  },


  {
    image: QR,
    icon: iQR,
    thumbnail: TQR,
    Downloads: '10 K+',
    title: 'QR Code & Barcode Scanner',
    thumbnailtext: 'QR Code & Barcode Scanner',
    button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudio.quik.qr.barcodescanner.reader',


  },


  {
    image: Sunglasses,
    icon: iSunglasses,
    thumbnail: TSunglasses,
    Downloads: '100 K+',
    title: 'Sunglasses Photo Editor',
    thumbnailtext: 'Sunglasses Photo Editor',
    button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudio.sunglasses.photoeditor.frames',


  },

  {
    image: Profile,
    icon: iProfile,
    thumbnail: TProfile,
    Downloads: '50 K+',
    title: 'Profile Picture Maker : PFP',
    thumbnailtext: 'Profile Picture Maker : PFP',
    button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudio.profile.picture.maker.video.editor',


  },
  {
    image: ClickCut,
    icon: iClickCut,
    thumbnail: TClickCut,
    Downloads: '10 K+',
    title: 'ClickCut Video Editor',
    thumbnailtext: 'ClickCut Video Editor',
    button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudio.clickcut.videoeditor.music',


  },








  // Add more games with their respective button links
];



const columnimages = [
  {
    image: video,
    thumbnail: Bookimage,
    title: 'Book Photo Frame ',
    Downloads: '10 M+',
    button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudio.books.photo.frame.collage',


  },


  {
    image: video,
    thumbnail: Loveimage,
    Downloads: '1 M+',
    title: 'Love Photo Frame',
    button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudio.android.love.animated.photo.frame.editor',


  },
  {
    image: video,
    thumbnail: Bridalimage,
    Downloads: '1 M+',
    title: 'Bridal Dress Photo Editor',
    button1Link: 'https://play.google.com/store/apps/details?id=com.bpva.royalbridal.womensuit.photomontage.maker',


  },
  {
    image: video,
    thumbnail: Cakeimage,
    Downloads: '10 M+',
    title: 'Cake Frame Editor',
    button1Link: 'https://play.google.com/store/apps/details?id=com.xenstudio.birthdaycake.photoframe',


  },

  {
    image: video,
    thumbnail: Framimage,
    Downloads: '10 K+',
    title: 'FramMe',
    button1Link: 'https://play.google.com/store/apps/details?id=com.frameme.photoeditor.collagemaker.effects',


  },










  // Add more games with their respective button links
];

const RandomColumns = ({ images }) => {

  const [clickedIndex, setClickedIndex] = useState(null);


  const handleImageClick = (index) => {
    // Toggle the state to show/hide buttons
    setClickedIndex(index === clickedIndex ? null : index);
  };


  const [randomIndices, setRandomIndices] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);


  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  // Generate random order of indices for all 9 images
  const generateRandomIndices = () => {
    const indices = [...Array(9).keys()]; // Create an array of indices [0, 1, 2, ..., 8]
    indices.sort(() => Math.random() - 0.5); // Shuffle the indices array randomly
    return indices;
  };

  useEffect(() => {
    const indices = generateRandomIndices();
    setRandomIndices(indices);
  }, []); // Run only once on component mount

  // Split the shuffled indices into three groups for three rows
  const rows = Array.from({ length: 3 }, (_, rowIndex) =>
    randomIndices.slice(rowIndex * 3, (rowIndex + 1) * 3)
  );

  return (
    <div className="random-columns">
      {rows.map((row, rowIndex) => (
        <div key={rowIndex}>
          {window.innerWidth >= 576 ? (
            <Row className='mb-3'>
              {row.map((index) => (
                <Col key={index} md={4} className="image-container" data-aos="fade-up">
                  <div className="position-relative" onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}>
                    <img
                      src={images[index].image}
                      alt=""
                      className="img-fluid"
                    />
                    <div className="image-overlay"></div>
                    {hoveredIndex === index && (
                      <div className="button-container">
                        <a href={images[index].button1Link} target="_blank" rel="noreferrer">
                          <img src={play} alt="" />
                        </a>

                      </div>
                    )}
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            rowIndex === 0 && (
<Carousel
  nextIcon={<BsChevronRight className="carousel-control-icon" />}
  prevIcon={<BsChevronLeft className="carousel-control-icon" />}
  indicators={false}
>
  {row.map((index, rowIndex) => (
    <Carousel.Item key={rowIndex}>
      {row.map((index) => (
        <div key={index} className="image-containerM" onClick={() => handleImageClick(index)}>
          <img src={images[index].image} alt="" className="img-fluid" />
          {clickedIndex === index && (
            <div className="button-container">
              <a href={images[index].button1Link}>
                <img src={play} alt="" />
              </a>
              {images[index].button2Link && (
                <a href={images[index].button2Link}>
                  <img src={ios} alt="" />
                </a>
              )}
            </div>
          )}
        </div>
      ))}
    </Carousel.Item>
  ))}
</Carousel>

            )
          )}
        </div>
      ))}
    </div>
  );
};


export const Apps = () => {


  

  // In your React component
useEffect(() => {
  document.title = "APPs"; // Set your desired page title here
  document.querySelector('meta[name="title"]').setAttribute("content", "APPs | Mobify");
  document.querySelector('meta[name="description"]').setAttribute("content", "Apps that makes a difference beyond imaginations beyond limits.");
  document.querySelector('meta[name="keywords"]').setAttribute("content", "mobify, mobify islamabad, game development, app development");
}, []);

// Add event listener to initialize AOS when the component mounts
useEffect(() => {
  AOS.init({
    once: true, // Ensure animation only occurs once
    offset: 270, // Adjust offset as needed to trigger animation earlier/later
    // disable: 'mobile', // Disable on mobile devices if needed
    startEvent: 'scroll', // Trigger animation on scroll event
    useClassNames: true, // Apply class names to elements
    initClassName: 'aos-init', // Class applied during initialization
    animatedClassName: 'aos-animate', // Class applied on animation
    // duration: 1000, // Duration of animations in milliseconds
    easing: 'ease', // Easing function for animations
  });
}, []);

  const [rotation, setRotation] = useState(180);


  useEffect(() => {
    setTimeout(() => {
      setRotation(0); // Update the rotation to 0 degrees after a delay
    }, 1000); // Adjust the delay as needed
  }, []); // Run once after the component mounts


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AppAnim, // Pass the imported animation data
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };




  return (
    <div className='portfolio apps  AppsDiv'>
      <div className="div1 apps ">

        <img src={Mbanner} className='Mblock portfolioBanner'  alt="" />

        <img src={obj1} className='obj1 Lblock' alt="" />
        <img src={obj2} className='obj2 Lblock' alt="" />
        <img src={obj3} className='obj3 Lblock' alt="" />


        <img
          src={bannerImage}
          alt="Games Main Banner"
          className="banner-image"
          style={{ transform: `rotate(${rotation}deg)` }} // Apply the rotation dynamically
        />


        <Container fluid>
          <Row className='justify-content-center'> 
            <Col xs={12} md={5} lg={5} className='col Lblock portfoliologo'>
              <img className='gameImage' src={LogoAppsM} loading="eager" alt="" />
            </Col>
            <Col xs={12}  lg={6} className='col Mblock'>
              <img className='gameImage' src={LogoSvg} loading="eager" alt="" />
            </Col>
            <Col xs={12}  md={6} lg={6} className='appDiv col'>
              {/* <Lottie className='Lottie_Anim' options={defaultOptions} height={749} width={967} /> */}

              <Col xs={12} md={6} lg={6} className='animDiv col Lblock'>
                <Lottie className='Lottie_Anim' options={defaultOptions} style={{width:'44vw', height:'auto'}} />
              </Col>

              <Col xs={12} lg={6} className='animDiv col Mblock'>
  <Lottie className='Lottie_Anim' options={defaultOptions} style={{ width: 'auto', height: '56vh' }} />
</Col>

            </Col>
          </Row>
        </Container>
      </div>
      <div className="div2 " >
        {/* Static Images */}
        <img src={Div2Img1} className='Div2Img1 Lblock' alt="" />
        <img src={Div2Img2} className='Div2Img2 Lblock' alt="" />
        <img src={Div2Img3} className='Div2Img3 Lblock' alt="" />
        <img src={Div2Img4} className='Div2Img4 Lblock' alt="" />
        <Container className='Lblock'>




          <RandomColumns images={images} />
          <div className="boy-container">
            {/* <img className='boy' src={Boy} alt="" /> */}
          </div>
        </Container>

        <div className='Mblock'>
    <AsNavFor items={mobileimages}/>
      
    </div>


<div className='Mblock' data-aos="fade-up"
     data-aos-duration="1500">

<Container style={{width:'90%'}}>

      {columnimages.map((item, index) => (
        <Row key={index} className='portfolio-rows'>
          <Col xs={4}>
            <a href={item.button1Link}>
              <img src={item.thumbnail} alt="thumbnail" />
            </a>
          </Col>
          <Col xs={8}>
            <h4>{item.title}</h4>
            <h5><img src={DownloadIcon} className="DownloadIcon" alt="" />  {item.Downloads}</h5>
          </Col>
        </Row>
      ))}


  
</Container>


</div>

      </div>
    </div>
  );
};
