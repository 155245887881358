import React, { useEffect } from 'react'
import './Career.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Lottie from 'react-lottie';
import { useState } from 'react';
import axios from 'axios'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import CustomAccordion from '../Common/CustomAccordion';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Carousel } from 'react-bootstrap';

import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'; // Import arrow icons




import Div3Back from '../../Assets/Website Assets/Career/Div3Back.webp'

import lunch from '../../Assets/Website Assets/Career/lunch.webp'
import transport from '../../Assets/Website Assets/Career/transport.webp'
import time from '../../Assets/Website Assets/Career/time.webp'
import security from '../../Assets/Website Assets/Career/security.webp'
import health from '../../Assets/Website Assets/Career/life.webp'



import get from '../../Assets/Website Assets/Career/get.webp'

import get1 from '../../Assets/Website Assets/Career/get1.webp'

import get2 from '../../Assets/Website Assets/Career/get2.webp'
import get3 from '../../Assets/Website Assets/Career/get3.webp'
import get4 from '../../Assets/Website Assets/Career/get4.webp'
import get5 from '../../Assets/Website Assets/Career/get5.webp'
import get6 from '../../Assets/Website Assets/Career/get6.webp'



import blueprint from '../../Assets/Website Assets/About/Blueprint.webp'


import div5img1 from '../../Assets/Website Assets/Career/div5img1.webp';
import div5img2 from '../../Assets/Website Assets/Career/div5img2.webp';


import ManAnim from '../../Assets/Website Assets/Animations/New/Career boy.json'
import WomanAnim from '../../Assets/Website Assets/Animations/New/Career girl.json'


import bannerImage from '../../Assets/Website Assets/Games/Games Main Banner.webp'; // Replace 'games-main-banner.jpg' with your actual image file

import Sphere1 from '../../Assets/Website Assets/Career/Sphere1.webp'
import Sphere2 from '../../Assets/Website Assets/Career/Sphere2.webp'
import Sphere3 from '../../Assets/Website Assets/Career/Sphere3.webp'
import Sphere4 from '../../Assets/Website Assets/Career/Sphere4.webp'

import Essence1 from '../../Assets/Website Assets/Career/Essence1.webp'
import Essence2 from '../../Assets/Website Assets/Career/Essence2.webp'
import Essence3 from '../../Assets/Website Assets/Career/Essence3.webp'
import Essence4 from '../../Assets/Website Assets/Career/Essence4.webp'


import GetImg1 from '../../Assets/Website Assets/Career/GetImg1.webp'
import GetImg2 from '../../Assets/Website Assets/Career/GetImg2.webp'
import GetImg3 from '../../Assets/Website Assets/Career/GetImg3.webp'
import GetImg4 from '../../Assets/Website Assets/Career/GetImg1.webp'


import Mbanner from '../../Assets/Website Assets/Games/GamesBackM.webp'

import Maccord from '../../Assets/Website Assets/About/M-accord.webp'
import Oaccord from '../../Assets/Website Assets/About/O-accord.webp'
import Baccord from '../../Assets/Website Assets/About/B-accord.webp'
import Iaccord from '../../Assets/Website Assets/About/I-accord.webp'
import FYaccord from '../../Assets/Website Assets/About/FY-accord.webp'

import H from '../../Assets/Website Assets/Career/Mhealth.webp'
import T from '../../Assets/Website Assets/Career/Mtransport.webp'
import L from '../../Assets/Website Assets/Career/Mlunch.webp'
import P from '../../Assets/Website Assets/Career/Mpaid.webp'
import S from '../../Assets/Website Assets/Career/Msecurity.webp'




export const Career = () => {



  
// In your React component
useEffect(() => {
  document.title = "Career"; // Set your desired page title here
  document.querySelector('meta[name="title"]').setAttribute("content", "Career | Mobify");
  document.querySelector('meta[name="description"]').setAttribute("content", "Transforming passions into professions.");
  document.querySelector('meta[name="keywords"]').setAttribute("content", "mobify, mobify islamabad, game development, app development");
}, []);

const accordionItems = [
  {
    imageSrc: Maccord,
    title: 'Mastery',
    initialText: 'Pursuing excellence in every endeavor, constantly refining ',
    expandedText: 'skills,  and achieving mastery in the ever-evolving landscape of technology.',
  },
  {
    imageSrc: Oaccord,
    title: 'Optimism',
    initialText: 'Staying positive and hopeful, believing in challenges as',
    expandedText: 'opportunities for innovation and success.',
  },
  {
    imageSrc: Baccord,
    title: 'Balance',
    initialText: 'We believe in blending innovation with stability, speed with quality,',
    expandedText: 'and flexibility with standardization for optimal performance.',
  },
  {
    imageSrc: Iaccord,
    title: 'Innovation',
    initialText: 'Embracing a culture of fearless exploration, pushing boundaries,',
    expandedText: 'and bringing visionary ideas to life through cutting-edge innovation',
  },
  {
    imageSrc: FYaccord,
    title: 'Flexibility',
    initialText: 'We, as an agile organization, respond swiftly to ',
    expandedText: 'new opportunities, and proactively ensuring sustained excellence in the ever-changing IT landscape.',
  },
  // Add more items as needed
];






// Add event listener to initialize AOS when the component mounts
useEffect(() => {
  AOS.init({
    once: true, // Ensure animation only occurs once
    offset: 270, // Adjust offset as needed to trigger animation earlier/later
    // disable: 'mobile', // Disable on mobile devices if needed
    startEvent: 'scroll', // Trigger animation on scroll event
    useClassNames: true, // Apply class names to elements
    initClassName: 'aos-init', // Class applied during initialization
    animatedClassName: 'aos-animate', // Class applied on animation
    // duration: 1000, // Duration of animations in milliseconds
    easing: 'ease', // Easing function for animations
  });
}, []);



  const [myjobs,setJobs] = useState([]);
  const [Mappedjobs,setMappedJobs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://career.mobify.tech/api/job-post-list');
        setJobs(response.data.data);
        console.log(myjobs)

      } catch (error) {
        console.log("Error Fetching data", error);
      }
    };

    fetchData();
  }, []);


  useEffect(()=>
  {
    const baseUrl = 'https://career.mobify.tech/';

    const mappedJobs = myjobs.map(job => ({
      category: job.department.name, // Assuming 'department' contains the category
      title: job.name,
      description: 'View job description', // Assuming a static description
      applyLink: `${baseUrl}${job.slug}/apply`, // Assuming a static apply link
      daysAgo: `By: ${job.last_submission_date}` // You need to calculate this based on your requirements
  }));

  setMappedJobs(mappedJobs)
  console.log('Mapped = ',mappedJobs)

  },[myjobs])


  const [activeCategory, setActiveCategory] = useState('all');

  const handleTabSelect = (category) => {
    setActiveCategory(category);
  };

  const filteredJobs = activeCategory === 'all' ? Mappedjobs : Mappedjobs.filter(job => job.category === activeCategory);



  const Anim1 = {
    loop: true,
    autoplay: true,
    animationData: WomanAnim, // Pass the imported animation data
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const Anim2 = {
    loop: true,
    autoplay: true,
    animationData: ManAnim, // Pass the imported animation data
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


  return (
    <div className='career'>
      
      <div className="div1">

        <img src={Sphere1} className='Sphere1 Lblock' alt="" />
        <img src={Sphere2} className='Sphere2 Lblock' alt="" />
        <img src={Sphere3} className='Sphere3 Lblock' alt="" />

        <img src={Mbanner} className='Mblock portfolioBanner' alt="" />


        <img
        src={bannerImage}
        alt="Games Main Banner"
        className="banner-image Lblock"
        // style={{ transform: `rotate(${rotation}deg)` }} // Apply the rotation dynamically
      />

        <Container>
          <Row style={{position:'relative'}}>
          <img src={Sphere4} className='Sphere4 Lblock' alt="" />

          <Col  sm={12} md={5} lg={4} xl={6} className='col Lblock' >
              <h1>Transforming<br /> Passions into<br /> Professions</h1>
            </Col>
            <Col  sm={12} md={5} className='col Mblock' >
              <h1>Transforming passions into professions</h1>
            </Col>
            <Col sm={12} md={6} className='col LAnim'>
            <Lottie options={Anim1} style={{width:'42vw', height:'auto'}} />


</Col>
<Col sm={12} md={6} className='col MAnim'>
            <Lottie className='hero' options={Anim1} style={{width:'97vw', height:'auto'}} />
            {/* <Lottie className='hero' options={Anim1} style={{ height: '100%', width: '100%' }} /> */}

</Col>
            
      </Row>
        </Container>
  </div>
  <div className="div2 essence"> 

        <img src={Essence1} className='Essence1 Lblock' alt=""  data-aos="fade-up" data-aos-duration="1000"/>
        <img src={Essence2} className='Essence2 Lblock' alt="" data-aos="fade-up" data-aos-duration="1000"/>
        <img src={Essence3} className='Essence3 Lblock' alt="" data-aos="fade-up" data-aos-duration="1000"/>
        <img src={Essence4} className='Essence4 Lblock' alt="" data-aos="fade-up" data-aos-duration="1000"/>

        <Col xs={12} className='Mblock'> {/* Use xs={12} to span the full width on extra small screens */}
    <h2 className='essence-head'>Mobi<span style={{color:'#F69E1F'}}>fy</span> Essence</h2>
  </Col>


<div className='MobAccord Mblock'>

<CustomAccordion items={accordionItems} />

</div>






<Container>
        <Row>
  <Col xs={12} className='Lblock' data-aos="fade-up"      data-aos-duration="1000"> {/* Use xs={12} to span the full width on extra small screens */}
    <h2>Mobi<span style={{color:'#F69E1F'}}>fy</span> Essence</h2>
  </Col>


  <Row className='blueprints row1' data-aos="fade-up"
     data-aos-duration="1000">




<Col xs={{span:9}} md={{ span: 4, offset: 0 }} lg={{ span: 3, offset: 0 }}  xl={{ span: 2, offset: 0 }}  className='mt5 topMargin1 Lblock'><h3><span>M</span>astery</h3>
  <h4>Pursuing excellence in every endeavor, constantly refining skills, and achieving mastery in the ever-evolving landscape of technology. </h4>
 </Col>
 <Col xs={{span:9, offset: 0}} md={{ span: 4, offset: 0 }} lg={{ span: 3, offset: 0 }} xl={{ span: 2, offset: 0 }}   className='mt5 Lblock'><h3><span>B</span>alance</h3>
  <h4>We believe in blending innovation with stability, speed with quality, and flexibility with standardization for optimal performance. </h4>
 </Col>


 <Col md={{ span: 4, offset: 0 }} lg={{ span: 3, offset: 0 }}  xl={{ span: 2, offset: 0 }}   className='mt5 Lblock'><h3><span>F</span>lexibilit<span>y</span></h3>
  <h4>We, as an agile organization, respond swiftly to new opportunities, and proactively ensuring sustained excellence in the ever-changing IT landscape. </h4>
 </Col>
      


    

 <Col md={{ span: 4, offset: 1 }} lg={{ span: 3, offset: 1 }}  xl={{ span: 2, offset: 1 }}   className='mt-5 Optimism m-top Lblock'><h3><span>O</span>ptimism</h3>
  <h4>Staying positive and hopeful, believing in challenges as opportunities for innovation and success. </h4>
 </Col>

 <Col md={{ span: 4, offset: 0 }} lg={{ span: 3, offset: 0 }} xl={{ span: 2, offset: 0 }}  className='mt-5 m-top Lblock'><h3><span>I</span>nnovation</h3>
  <h4>Embracing a culture of fearless exploration, pushing boundaries, and bringing visionary ideas to life through cutting-edge innovation
</h4>
 </Col>

 </Row>


  
  <Row className='blueprints'>

  <Col xs={12} className='blueprintCol Lblock'>
  <div className='blueprint Lblock' >

  <img src={blueprint} alt=""  />
  
  </div>


  </Col>
  








  </Row>


  
</Row>

        </Container>
  </div>


  <div className="div3"  style={{backgroundImage: `url(${Div3Back})`}}>

  <Container>
  <Row>
    <Col md={12} className='col' data-aos="fade-up" data-aos-duration="1000">
      <h1 className='Lblock'>Beyond the Paycheck</h1>
      <h2 className='Mblock'>Beyond the Paycheck</h2>

    </Col>
  </Row>
  <Row className='manonchair Lblock' data-aos="fade-up" data-aos-duration="1500">
        {/* <img src={manonchair} alt="" /> */}
        
        <Lottie style={{position:'absolute',top:'-180px',left:'41vw',zIndex:'11',width:'43.5vw', height:'auto'}} className='male' options={Anim2} height={850} width={1095} />



  </Row>


  <Row className='perks Lblock' data-aos="fade-up">
    <Col md={8} className='perks-col'>
      <Row className='justify-content-left'>
        <Col xs={4} md={2}>
          <img src={health} alt="" />
        </Col>
        <Col xs={8} md={8}>
          <h3>Health Insurance</h3>
          <h4>Peace of mind with health coverage for you and your dependents.</h4>
        </Col>
      </Row>
    </Col>
    <Col  md={8} className='perks-col'>
      <Row >
        <Col xs={4} md={2}>
          <img src={transport} alt="" />
        </Col>
        <Col xs={8} md={8}>
          <h3>Free Transport</h3>
          <h4>Free transportation to and from work lets you focus on the job.</h4>
        </Col>
      </Row>
    </Col>
    <Col md={8} className='perks-col'>
      <Row >
        <Col xs={4} md={2}>
          <img src={lunch} alt="" />
        </Col>
        <Col xs={8} md={8}>
          <h3>Free Lunch</h3>
          <h4>Fuel your workday with delicious and free lunches provided by the company.</h4>
        </Col>
      </Row>
    </Col>


    <Col md={8} className='perks-col'>
      <Row >
        <Col xs={4} md={2}>
          <img src={time} alt="" />
        </Col>
        <Col xs={8} md={8}>
          <h3>Paid Time OFF
          </h3>
          <h4>Recharge and relax with paid time off to enjoy vacations and personal time.</h4>
        </Col>
      </Row>
    </Col>



    <Col md={8} className='perks-col'>
      <Row >
        <Col xs={4} md={2}>
          <img src={security} alt="" />
        </Col>
        <Col xs={8} md={8}>
          <h3>Financial Security </h3>
          <h4>Your future is secure with contributions to your EOBI and Provident Fund</h4>
        </Col>
      </Row>
    </Col>

  
  </Row>


  <div className='Mblock'>


<Row data-aos="fade-up" data-aos-duration="1500">
  <Col xs={3}>
  <img src={H} alt="" />
  </Col>

  <Col xs={8}>
  <h3>Health Insurance</h3>
  <p>Peace of mind with health coverage for you and your dependents.</p>
  </Col>

</Row>

<Row data-aos="fade-up" data-aos-duration="1500">
  <Col xs={3} >
  <img src={T} alt="" />
  </Col>

  <Col xs={8}>
  <h3>Free Transport</h3>
  <p>Free transportation to and from work lets you focus on the job.</p>
  </Col>

</Row>

<Row data-aos="fade-up" data-aos-duration="1500">
  <Col xs={3}>
  <img src={L} alt="" />
  </Col>

  <Col xs={8}>
  <h3>Free Lunch</h3>
  <p>Fuel your workday with delicious and free lunches provided by the company.</p>
  </Col>

</Row>



<Row data-aos="fade-up" data-aos-duration="1500">
  <Col xs={3}>
  <img src={P} alt="" />
  </Col>

  <Col xs={8}>
  <h3>Paid Time OFF</h3>
  <p>Recharge and relax with paid time off to enjoy vacations and personal time.</p>
  </Col>

</Row>


<Row data-aos="fade-up" data-aos-duration="1500">
  <Col xs={3}>
  <img src={S} alt="" />
  </Col>

  <Col xs={8}>
  <h3>Financial Security</h3>
  <p>Your future is secure with contributions to your EOBI and Provident Fund</p>
  </Col>

</Row>



</div>

  <Row className='manonchair Mblock' data-aos="fade-up" data-aos-duration="1500">
        {/* <img src={manonchair} alt="" /> */}
        
        <Lottie  className='male' options={Anim2} height={526} width={658} />


  </Row>
</Container>

  </div>

<div className="div4">
<img src={GetImg1} className='GetImg1 Lblock' alt="" data-aos="fade-up" data-aos-duration="1000"/>
<img src={GetImg2} className='GetImg2 Lblock' alt="" data-aos="fade-up" data-aos-duration="1000"/>
<img src={GetImg3} className='GetImg3 Lblock' alt="" data-aos="fade-up" data-aos-duration="1000"/>
<img src={GetImg4} className='GetImg4 Lblock' alt="" data-aos="fade-up" data-aos-duration="1000"/>


<Container>
<Row>
<Col md={12} className='col'data-aos="fade-up" data-aos-duration="1000">
      <h2>How to Get In</h2>
    </Col>
    </Row>

  
<Row className='justify-content-center Lblock' data-aos="fade-up" data-aos-duration="1500">
  
<Col style={{position:'relative'}} md={4} lg={3} className='getIn'>
<img src={get1} alt="" />
<div className='job-text'>

<h3>Application</h3>
<h4>You submit your resume and cover letter for the job opening.</h4>
</div>

</Col>




<Col style={{position:'relative'}} md={4} lg={3} className='getIn'>
<img src={get2} alt="" />
<div className='job-text'>

<h3>Shortlisting</h3>
<h4>Recruiters screen applications to select candidates who meet the qualifications.</h4>
</div>

</Col>
<Col style={{position:'relative'}} md={4} lg={3} className='getIn'>
<img src={get3} alt="" />
<div className='job-text'>

<h3>Online Interview</h3>
<h4>Shortlisted candidates participate in an initial interview, often conducted virtually.</h4>
</div>

</Col>

<Col style={{position:'relative'}} md={4} lg={3} className='getIn'>
<img src={get4} alt="" />
<div className='job-text'>

<h3>Technical Assessment</h3>
<h4>Some roles may involve a skills test to assess specific technical abilities.</h4>
</div>

</Col>

<Col style={{position:'relative'}} md={4} lg={3} className='getIn'>
<img src={get5} alt="" />
<div className='job-text'>

<h3>Final Interview</h3>
<h4>Top candidates meet with the hiring team for a more in-depth interview.</h4>
</div>

</Col>

<Col style={{position:'relative'}} md={4} lg={3} className='getIn'>
<img src={get6} alt="" />
<div className='job-text'>

<h3>Onboarding</h3>
<h4>Once selected, the new hire goes through an onboarding process to get acquainted with the company and their role.</h4>
</div>

</Col>

</Row>


<Row className='Mblock getinrow'>
  
<div className="carousel-container "  data-aos="fade-up" data-aos-duration="1500">
 
          
      <Carousel
        nextIcon={<BsChevronRight className="carousel-control-icon" />}
        prevIcon={<BsChevronLeft className="carousel-control-icon" />}
        indicators={false}
      >
        <Carousel.Item>
        <img src={get} alt="" />
        <div className='job-text'>
<h2>1</h2>

<h3>Application</h3>
<h4>You submit your resume and cover letter for the job opening.</h4>
</div>

        </Carousel.Item>

        <Carousel.Item>
        <img src={get} alt="" />
        <div className='job-text'>
        <h2>2</h2>

<h3>Shortlisting</h3>
<h4>Recruiters screen applications to select candidates who meet the qualifications.</h4>
</div>

        </Carousel.Item>


        <Carousel.Item>
        <img src={get} alt="" />
        <div className='job-text'>
        <h2>3</h2>

<h3>Online Interview</h3>
<h4>Shortlisted candidates participate in an initial interview, often conducted virtually.</h4>
</div>
        </Carousel.Item>


        <Carousel.Item>
        <img src={get} alt="" />
        <div className='job-text'>
        <h2>4</h2>

<h3>Final Interview</h3>
<h4>Top candidates meet with the hiring team for a more in-depth interview.</h4>
</div>

        </Carousel.Item>


        <Carousel.Item>
        <img src={get} alt="" />
        <div className='job-text'>
        <h2>5</h2>

<h3>Technical Assessment</h3>
<h4>Some roles may involve a skills test to assess specific technical abilities.</h4>
</div>
        </Carousel.Item>


        <Carousel.Item>
        <img src={get} alt="" />
        <div className='job-text'>
        <h2>6</h2>

<h3>Onboarding</h3>
<h4>Once selected, the new hire goes through an onboarding process to get acquainted with the company and their role.</h4>
</div>

        </Carousel.Item>



      </Carousel>
    </div>


    </Row>
</Container>

</div>



<div className="div5">
<img src={div5img1} className='div5img1 Lblock' alt="" data-aos="fade-up" data-aos-duration="1000"/>
<img src={div5img2} className='div5img2 Lblock' alt="" data-aos="fade-up" data-aos-duration="1000"/>


<Container>
        <Row>
          <Col md={12} data-aos="fade-up" data-aos-duration="1000">
            <h2>Opportunities to Grab</h2>
          </Col>

          <Col md={12} className='Lblock'>
          <Tabs defaultActiveKey="all" onSelect={handleTabSelect}>
    {['all', ...new Set(Mappedjobs.map(job => job.category))].map(category => (
        <Tab key={category} eventKey={category} title={category === 'all' ? 'All' : category.charAt(0).toUpperCase() + category.slice(1)}>
            <Row className='jobs'>
                {filteredJobs
                    .filter(filteredJob => category === 'all' || filteredJob.category === category)
                    .slice(0, 3)
                    .map((filteredJob, index) => (
                        // <Col key={index} md={3} className='job' md={{ span: 3, offset: index === 0 ? 0 : 1 }}>
                        <Col key={index}  className='job' md={{ span: 3, offset: 1 }} >
                            <Row>
                                <Col xs={7}>
                                    <h3>{filteredJob.title}</h3>
                                    <h5>{filteredJob.description}</h5>
                                    <a href={filteredJob.applyLink}>+ Apply Now</a>
                                </Col>
                                <Col xs={5}>
                                    <p>{filteredJob.daysAgo}</p>
                                </Col>
                            </Row>
                        </Col>
                    ))}
            </Row>
            <Row className='jobs'>
                {filteredJobs
                    .filter(filteredJob => category === 'all' || filteredJob.category === category)
                    .slice(3, 6)
                    .map((filteredJob, index) => (
                        <Col key={index}  className='job' md={{ span: 3, offset: index === 0 ? 2 : 1 }}>
                            <Row>
                                <Col xs={7}>
                                    <h3>{filteredJob.title}</h3>
                                    <h5>{filteredJob.description}</h5>
                                    <a href={filteredJob.applyLink}>+ Apply Now</a>
                                </Col>
                                <Col xs={5}>
                                    <p>{filteredJob.daysAgo}</p>
                                </Col>
                            </Row>
                        </Col>
                    ))}
            </Row>
        </Tab>
    ))}
</Tabs>





    </Col>

    <Col md={12} className='Mblock' data-aos="fade-up" data-aos-duration="1500">
    <Tabs
  defaultActiveKey="all"
  id="justify-tab-example"
  className="mb-3"
  fill
  activeKey={activeCategory}
  onSelect={handleTabSelect}
>
  {['all', ...new Set(Mappedjobs.map(job => job.category))].map(category => (
    <Tab key={category} eventKey={category} title={category === 'all' ? 'All' : category.charAt(0).toUpperCase() + category.slice(1)}>
      <Carousel interval={null}  controls={true} className="d-md-none"      
        nextIcon={<BsChevronRight className="carousel-control-icon" />}
        prevIcon={<BsChevronLeft className="carousel-control-icon" />}
      >
        {filteredJobs
          .filter(filteredJob => category === 'all' || filteredJob.category === category)
          .map((filteredJob, index) => (
            <Carousel.Item key={index}>
              <Row className='jobs'>
                <Col xs={12} className='job'>
                  <Row>
                    <Col xs={7}>
                      <h3>{filteredJob.title}</h3>
                      <h5>{filteredJob.description}</h5>
                      <a href={filteredJob.applyLink}>+ Apply Now</a>
                    </Col>
                    <Col xs={5}>
                                    <p>{filteredJob.daysAgo}</p>
                      </Col>
                  </Row>
                </Col>
              </Row>
            </Carousel.Item>
          ))}
      </Carousel>
    </Tab>
  ))}
</Tabs>


    </Col>





        </Row>

</Container>

</div>



    </div>
  )
}
