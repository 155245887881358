import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink,useLocation  } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';

export const MobHeader2 = () => {

  

    const [activePage, setActivePage] = useState('');
    const location = useLocation();
  
    // Update activePage state when location changes
    useEffect(() => {
      setActivePage(location.pathname);
    }, [location]);
  
    // Scroll to the top of the page when a Navbar link is clicked
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };



  return (
    <div className='mobheader Mblock' >
<Navbar  fixed="bottom" bg="light" variant="light">
  <Container fluid>
    <div className="navbar-scroll">
      <Nav className="justify-content-center navbar-nav-scroll">
        <Nav.Item>
          <Nav.Link as={NavLink} to="/" onClick={scrollToTop}>Home</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/games" onClick={scrollToTop}>Games</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/apps" onClick={scrollToTop}>Apps</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/services" onClick={scrollToTop}>Services</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/about" onClick={scrollToTop}>About</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/career" onClick={scrollToTop}>Career</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/lifeatmobify" onClick={scrollToTop}>Mobify Life</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/contact" onClick={scrollToTop} className='contactMob'>Contact Us</Nav.Link>
        </Nav.Item>
        {/* Add more navigation items here if needed */}
      </Nav>
    </div>
  </Container>
</Navbar>


    </div>
  )
}
