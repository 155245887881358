import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'; // Import arrow icons


const AwardsCarousel = ({ awards }) => {
  return (
    <Carousel
    nextIcon={<BsChevronRight className="carousel-control-icon" />}
    prevIcon={<BsChevronLeft className="carousel-control-icon" />}
    indicators={false}
  >
      {awards.map((award) => (
        <Carousel.Item key={award.id}>
          <Card>
            <Card.Img variant="top" src={award.image} />
            <Card.Body>
              <Card.Text>
                <h4>{award.title} {award.year}</h4>

              </Card.Text>
            </Card.Body>
          </Card>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default AwardsCarousel;
